import { Component, OnInit, Input,AfterViewInit, HostListener, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
//import { FirestoreService } from '../services/firestore.service'; 
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { OrderArrayService } from "../order-array.service";


declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit, AfterViewInit {
  
  constructor(public orders:OrderArrayService ,private dialog: MatDialog,private afs: AngularFirestore,private functions: AngularFireFunctions, private cd: ChangeDetectorRef, private DialogSer: MatDialog) {
 
   }
  @Input() amount: number;
  @Input() newItem: any;
  @Input() Order: object;
  @ViewChild('cardElement') cardElement: ElementRef;

  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  cloud_function_err
  confirmation;
  loading = false;
  processingError;


  ngOnInit() {

    
  }
  ngAfterViewInit() {
    //change the public key to the connected account public key!!!!
    
    if (this.Order["mode"] == 'test') {
      // console.log('restaurant doc', this.orderForm.restaurantDoc);
      let pk = this.orders.selectedRestaurant.value.testPublishableKey;
      this.stripe = Stripe(pk);
    } else {
      let pk = this.orders.selectedRestaurant.value.livePublishableKey;
      this.stripe = Stripe(pk);
    }


    


    const elements = this.stripe.elements();

    this.card = elements.create('card');


    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }
  async handleForm(e) {
    this.loading = true;
    e.preventDefault();

    const { paymentMethod, error } = await this.stripe.createPaymentMethod('card', this.card);
    if (error) {
      // Inform customer that there was an error
      this.processingError = error.message;
      this.loading = false;
    }
    else {
      this.loading = true;
      // const user = await this.auth.getUser(); 
      const fun = this.functions.httpsCallable('newCardAddToOrder');
      try {
        this.newItem.price=parseFloat(this.newItem.price);
        this.confirmation = await fun({ source: paymentMethod.id, order: this.Order, newItem: this.newItem, amount: this.amount }).toPromise();
        this.cd.detectChanges();
        this.DialogSer.closeAll();
      } catch (err) {
        alert(err)
      }


      
      //auto navigate to the profile page
      if (this.confirmation) {
        this.loading = false;
        this.dialog.closeAll();
      }


    }
  }





}
