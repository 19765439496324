import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { AppRoutingModule } from './app-routing.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { StatsComponent } from './stats/stats.component';

// import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppShellComponent } from './app-shell/app-shell.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { MatTableModule } from '@angular/material/table';
import "@angular/compiler";
import { GoogleMapsModule } from "@angular/google-maps";

import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { GoogleSigninDirective } from './google-signin.directive';
import { OrdersComponent } from './orders/orders.component';
import { AdminComponent } from './admin/admin.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import { GlobalLoadingComponent } from './global-loading/global-loading.component';
import { MenuEditorComponent } from './menu-editor/menu-editor.component';
import { RemoveItemComponent } from './remove-item/remove-item.component';
import { EditOrderDialogComponent } from './edit-order-dialog/edit-order-dialog.component';
import { GooglePlaceDirective } from './google-places.directive';
import { ConfirmComponent } from './confirm/confirm.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { InfoComponent } from './info/info.component';
import { NewOrderConfirmDialogComponent } from './new-order-confirm-dialog/new-order-confirm-dialog.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { PreviousReservationComponent } from './previous-reservation/previous-reservation.component';
import { OrderTableComponent } from './order-table/order-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { IonicModule } from '@ionic/angular';
import { CheckinsComponent } from './checkins/checkins.component';
import { AppleSigninDirective } from './apple-signin.directive';
import { ItemEditorComponent } from './item-editor/item-editor.component';
import { ChangeTipDialogComponent } from './change-tip-dialog/change-tip-dialog.component';
import { EditTimeMenuComponent } from './edit-time-menu/edit-time-menu.component';
import { EditTimeDialogComponent } from './edit-time-dialog/edit-time-dialog.component';
import { EditNotesMenuComponent } from './edit-notes-menu/edit-notes-menu.component';
import { EditNoteDialogComponent } from './edit-note-dialog/edit-note-dialog.component';
import { PrintNotificationSettingComponent } from './print-notification-setting/print-notification-setting.component';


@NgModule({
  declarations: [
    AppComponent,
    StatsComponent,
    AppShellComponent,
    LoginComponent,
    GoogleSigninDirective,
    OrdersComponent,
    AdminComponent,
    ItemDetailComponent,
    GlobalLoadingComponent,

    MenuEditorComponent,
    RemoveItemComponent,
    EditOrderDialogComponent,
    GooglePlaceDirective,
    ConfirmComponent,
    StripePaymentComponent,
    InfoComponent,
    NewOrderConfirmDialogComponent,
    ReservationsComponent,
    PreviousReservationComponent,
    OrderTableComponent,
    CheckinsComponent,
    AppleSigninDirective,
    ItemEditorComponent,
    ChangeTipDialogComponent,
    EditTimeMenuComponent,
    EditTimeDialogComponent,
    EditNotesMenuComponent,
    EditNoteDialogComponent,
    PrintNotificationSettingComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    // GuidedTourModule,
    MatTooltipModule,
    MatExpansionModule,
    GoogleMapsModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    AngularFireModule.initializeApp(environment.fireConfig, 'Inhouse Dashboard'),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireFunctionsModule,
    CommonModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatTableModule, MatMenuModule, MatRadioModule, MatSlideToggleModule, MatCheckboxModule, MatSelectModule, MatProgressBarModule, MatMomentDateModule, MatDatepickerModule, MatFormFieldModule, MatCardModule, MatTabsModule, MatButtonToggleModule, MatDialogModule, MatPaginatorModule, MatSortModule, IonicModule.forRoot()
  ],
  providers: [StatusBar,
    // GuidedTourService,
    SplashScreen, Deploy, GooglePlus, Insomnia],
  bootstrap: [AppComponent],
  entryComponents: [AdminComponent, ConfirmComponent, RemoveItemComponent, EditOrderDialogComponent, MenuEditorComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
