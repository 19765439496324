<div class="m-l-8 m-r-8 card orderTable">
  <mat-tab-group mat-stretch-tabs (selectedIndexChange)="updateItemDetail($event)">
    <mat-tab label="{{'Current'|translate}} ({{this.allOrderArray.length}})">

      <!-- Master Toggle Button -->

      <app-order-table *ngIf="allOrderArray.length > 0 else noCurrentOrders" [orders]='allOrderArray'
        [restaurant]='restaurant' [restaurantDoc] = 'this.selectedRestaurant' [modifiers]='modArray' [inhouseConfig]='inhouseConfig'></app-order-table>
      <ng-template #noCurrentOrders>
        <h2 class="text-center">{{'There are no current orders'|translate}}. 😭</h2>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{'Scheduled'|translate}} ({{this.futureOrderArray.length}})">

      <!-- Master Toggle Button -->

      <app-order-table *ngIf="futureOrderArray.length > 0 else noFutureOrders" [orders]='futureOrderArray'
        [restaurant]='restaurant' [restaurantDoc] = 'this.selectedRestaurant' [modifiers]='modArray' [inhouseConfig]='inhouseConfig'></app-order-table>
      <ng-template #noFutureOrders>
        <h2 class="text-center">{{'There are no future orders'|translate}}. 😭</h2>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="Scheduled ({{this.futureOrderArray.length}})">
      <div class="tabContainer">
        <div *ngIf="this.futureOrderArray.length > 0 && this.orderService.selectedOrder" class="selectorPanel">
          <mat-card [style.background]="( condition == i) ? '#defdff':'white'"
            *ngFor="let order of futureOrderArray; let i=index" class="mb-1" (click)="futureOrdersSelect(i) ">
            <b>Order # {{ order.id }}. </b>
            <p *ngIf="order.canceled" class="text-danger">Order Was Canceled</p>
            <p class="mb-0 mt-1">{{order.first | titlecase}} {{order.last | titlecase}}</p>
            <p class="mb-0 mt-1"> {{order.orderType}}</p>
            <p class="mb-0 mt-1 bold text-alert"> Scheduled:&nbsp; {{ order.futureOrderDate | date:'shortDate' }},
              {{ order.futureOrderTime }}</p>
            <p class="mb-0 mt-1">Placed:&nbsp; {{order.order_iso_time.seconds * 1000 | date:'short'}}</p>
          </mat-card>
        </div>

        <order-detail [order]='this.orderService.selectedOrder' [inhouseConfig]='inhouseConfig'
          *ngIf="futureOrderArray.length > 0 else noFutureOrders"></order-detail>
        <ng-template #noFutureOrders>
          <h2 class="text-center">There are no future orders. 😭</h2>
        </ng-template>



      </div>
    </mat-tab> -->

    <mat-tab label="{{'Completed'|translate}} ({{this.completeOrderArray.length}})">

      <!-- Master Toggle Button -->

      <app-order-table *ngIf="completeOrderArray.length > 0 else noCompletedOrders" [orders]='completeOrderArray'
        [restaurant]='restaurant' [restaurantDoc] = 'this.selectedRestaurant' [modifiers]='modArray' [inhouseConfig]='inhouseConfig'></app-order-table>
      <ng-template #noCompletedOrders>
        <h2 class="text-center">{{'There are no completed orders'|translate}}. 😭</h2>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Order History'|translate}}">
      <div class="">
        <h4>{{'contact representative'|translate}}</h4>
        <mat-form-field appearance="fill">
          <mat-label>{{'Enter a date range'|translate}}</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getOrders()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date'|translate}}</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date'|translate}}</mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="w-50 dateField">
            <input matInput [matDatepicker]="picker1" placeholder="Choose A Start Date" [formControl]="start"
              (dateChange)="getOrders(); ">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
         

          <mat-form-field class="w-50 dateField">
            <input matInput [matDatepicker]="picker2" placeholder="Choose An End Date" [formControl]="end"
              (dateChange)="getOrders(); ">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field> -->
        <div class="tabContainer">

          <app-order-table *ngIf="historyArray.length > 0 else noLookupOrders" [orders]='this.historyArray'
            [restaurant]='restaurant' [restaurantDoc] = 'selectedRestaurant' [modifiers]='modArray' [inhouseConfig]='inhouseConfig'></app-order-table>
          <!-- <div *ngIf="this.lookupArray.length > 0" class="selectorPanel">
              <mat-card [style.background]="( condition == i) ? '#dee7f2':'white'"
                *ngFor="let order of lookupArray; let i=index" class="" (click)="lookupOrdersSelect(i)" class="mb-1">
                <b>Order # {{ order.id }}. </b>
                <p *ngIf="order.canceled" class="text-danger">Order Was Canceled</p>
                <p class="mb-0 mt-1">{{order.first | titlecase}} {{order.last | titlecase}}</p>
                <p class="mb-0 mt-1"> {{order.orderType}}</p>
                <p class="mb-0 mt-1 text-alert" *ngIf="order.isFutureOrder"><b>Scheduled:&nbsp;
                    {{ order.futureOrderDate | date:'shortDate' }}, {{ order.futureOrderTime }}</b></p>

                <p class="mb-0 mt-1">Placed:&nbsp; {{order.order_iso_time.seconds * 1000 | date:'short'}}</p>
              </mat-card>
            </div>

            <order-detail [order]='this.orderService.selectedOrder' [inhouseConfig]='inhouseConfig'
              *ngIf="lookupArray.length > 0 else noLookupOrders"></order-detail> -->
          <ng-template #noLookupOrders>
            <h2 class="text-center">{{'No Orders lookup Found'|translate}}. </h2>
          </ng-template>

        </div>

        <!-- <pre>{{this.orderService.selectedOrder | json }}</pre> -->
      </div>



    </mat-tab>
  </mat-tab-group>
</div>