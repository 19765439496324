<section>
    <div class="example-label">Choose a time for Edit</div>
    <div class="example-button-row">
        <button class="time-button" mat-flat-button (click)="openEditTimeDialog('Display')" color="primary">Display
            Time</button>
        <button class="time-button" mat-flat-button (click)="openEditTimeDialog('Ordering')" color="accent">Ordering
            Time</button>
    </div>
    <div class="example-button-row">
        <button class="time-button"
            *ngIf="selectedRestaurant.value.takesDelivery==true||selectedRestaurant.value.takesDoordash==true"
            mat-flat-button (click)="openEditTimeDialog('Delivery')" color="warn">Delivery Time</button>
        <button class="time-button" mat-flat-button (click)="openEditTimeDialog('Lunch')" color="accent">Lunch
            time</button>

    </div>
</section>