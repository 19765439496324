import { Injectable } from '@angular/core';
import { OrderArrayService } from './order-array.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  reservations = [];
  todaysReservations = [];
  prevReservations = [];
  newResCount = 0;
  loadingRes=false;
  
  constructor(public orderService: OrderArrayService, private afs: AngularFirestore) {



    this.orderService.selectedRestaurant.subscribe(val => {
      if (val) {
        this.switchRestaurnt(val);

      }
    })




  }


  switchRestaurnt(newRestaurant) {

    this.afs.collection(`reservations/${this.orderService.selectedRestaurant.value.restaurantID}/upcoming`, ref => ref.orderBy('created', 'desc')).valueChanges({ idField: 'id' }).subscribe(val => {
      this.reservations = val;
      this.newResCount = this.reservations.filter(r => r.confirmed == false).length;
    });
    let start = new Date();
    let end = new Date();

    start.setHours(0, 0, 0, 0);

    end.setHours(23, 59, 0, 0);

    this.afs.collection(`reservations/${this.orderService.selectedRestaurant.value.restaurantID}/upcoming`,
      ref => ref
        .where('date', '>=', start)
        .where('date', '<=',end)
        .orderBy('date')
      )
      .valueChanges({ idField: 'id' }).subscribe(val => {
        this.todaysReservations = val;
      });  
  }  
}
