import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderArrayService } from '../order-array.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-time-dialog',
  templateUrl: './edit-time-dialog.component.html',
  styleUrls: ['./edit-time-dialog.component.css']
})
export class EditTimeDialogComponent implements OnInit {
  selectedRestaurant: any
  type: string;
  weekForm: FormGroup;
  dayOpenList = []
  dayCloseList = []
  filteredTimeOptions: string[][] = [];
  timeOptions: string[] = this.generateTimeOptions();
  daysOfWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  formError: string = '';


  constructor(private dialogRef: MatDialogRef<EditTimeDialogComponent>, private afs: AngularFirestore, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public orderArray: OrderArrayService
  ) {
    this.selectedRestaurant = data.selectedRestaurant
    this.type = data.type
    this.weekForm = this.fb.group({});
    console.log("open:", this.selectedRestaurant)
    if (this.type == "Display") {
      this.dayOpenList = this.selectedRestaurant.value.businessHours.open
      this.dayCloseList = this.selectedRestaurant.value.businessHours.close
    } else if (this.type == "Ordering") {
      this.dayOpenList = this.selectedRestaurant.value.orderStart
      this.dayCloseList = this.selectedRestaurant.value.orderEnd
    }
    else if (this.type == "Delivery") {
      this.dayOpenList = this.selectedRestaurant.value.deliveryStart
      this.dayCloseList = this.selectedRestaurant.value.deliveryEnd
    } else if (this.type == "Lunch") {
      this.dayOpenList = this.selectedRestaurant.value.lunchStart
      this.dayCloseList = this.selectedRestaurant.value.lunchEnd
    }
    this.daysOfWeek.forEach((day, index) => {
      if (this.dayOpenList[index] == '' || this.dayCloseList[index] == '' || this.dayCloseList[index] == 'close' || this.dayCloseList[index] == 'close') {
        this.weekForm.addControl(`${this.daysOfWeek[index]}`, this.fb.group({
          closed: [true],
          open: [{ value: this.dayOpenList[index], disabled: false },],
          close: [{ value: this.dayCloseList[index], disabled: false },]
        }));
      } else {
        this.weekForm.addControl(`${this.daysOfWeek[index]}`, this.fb.group({
          closed: [false],
          open: [{ value: this.dayOpenList[index], disabled: false }, [Validators.required, this.timeFormatValidator()]],
          close: [{ value: this.dayCloseList[index], disabled: false }, [Validators.required, this.timeFormatValidator()]]
        }));
      }
      this.filteredTimeOptions[index] = this.timeOptions;
    });


  }
  ngOnInit(): void {

  }
  filterTimes(event: Event, dayIndex: number, timeType: 'open' | 'close'): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredTimeOptions[dayIndex] = this.timeOptions.filter(option => option.toLowerCase().includes(filterValue));
  }
  onDayClosedChange(day: string, isChecked: boolean): void {
    const openControl = this.weekForm.get(`${day}.open`) as FormControl;
    const closeControl = this.weekForm.get(`${day}.close`) as FormControl;

    if (isChecked) {
      if (this.type == "Display") {
        openControl.clearValidators();
        closeControl.clearValidators();
        openControl.patchValue('close')
        closeControl.patchValue('')
      } else {
        openControl.clearValidators();
        closeControl.clearValidators();
        openControl.patchValue('')
        closeControl.patchValue('')
      }

      // openControl.disable();
      // closeControl.disable();
    } else {
      openControl.setValidators([Validators.required, this.timeFormatValidator()]);
      closeControl.setValidators([Validators.required, this.timeFormatValidator()]);
      openControl.enable();
      closeControl.enable();
    }
  }
  timeFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validTimeFormat = /^(1[0-2]|0?[1-9]):[0-5][0-9] (am|pm)$/i;
      return validTimeFormat.test(control.value) ? null : { invalidTimeFormat: true };
    };
  }

  generateTimeOptions(): string[] {
    let options: string[] = [];
    for (let i = 0; i < 24; i++) {
      let hour = i % 12 || 12;  // Convert 0 to 12 for 12-hour format
      let suffix = i < 12 ? 'am' : 'pm';
      options.push(`${hour}:00 ${suffix}`);
      options.push(`${hour}:10 ${suffix}`);
      options.push(`${hour}:20 ${suffix}`);
      options.push(`${hour}:30 ${suffix}`);
      options.push(`${hour}:40 ${suffix}`);
      options.push(`${hour}:50 ${suffix}`);
    }
    return options;
  }
  close() {
    this.dialogRef.close()
  }
  onSubmit(): void {
    const formValue = this.weekForm.value;
    const outputOpeningTimes = [];
    const outputClosingTimes = [];

    if (this.weekForm.invalid) {
      console.log('this.weekForm.invalid')
      this.formError = 'Please correct the errors before saving.';
      return; // Stop the submission process
    }

    for (const day in formValue) {
      outputOpeningTimes.push(formValue[day].open);
      outputClosingTimes.push(formValue[day].close);
    }

    console.log("Opening Times:", outputOpeningTimes);
    console.log("Closing Times:", outputClosingTimes);
    let temp = { ...this.selectedRestaurant.value }
    if (this.type == "Display") {
      temp.businessHours.open = outputOpeningTimes
      temp.businessHours.close = outputClosingTimes
    } else if (this.type == "Ordering") {
      temp.orderStart = outputOpeningTimes
      temp.orderEnd = outputClosingTimes
    }
    else if (this.type == "Delivery") {
      temp.deliveryStart = outputOpeningTimes
      temp.deliveryEnd = outputClosingTimes
    } else if (this.type == "Lunch") {
      temp.lunchStart = outputOpeningTimes
      temp.lunchEnd = outputClosingTimes
    }
    this.afs.doc('restaurants/' + this.selectedRestaurant.value.restaurantID).set(temp);
    this.selectedRestaurant.next(temp)
    this.dialogRef.close()
  }


}
