import { Directive, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertController } from '@ionic/angular';
import { auth } from 'firebase/app';
import { Platform } from '@ionic/angular';
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from './../environments/environment';
import { OrderArrayService } from './order-array.service';
import { Plugins } from '@capacitor/core'

const { Device, SignInWithApple } = Plugins;

@Directive({
  selector: '[appAppleSignin]'
})
export class AppleSigninDirective {

  constructor(
    private alertController: AlertController,
    public gplus: GooglePlus, 
    private afAuth: AngularFireAuth, 
    public orderServivce: OrderArrayService, 
    public platform: Platform, 
    private afs: AngularFirestore, 
    private orderServce: OrderArrayService
  ) { }

  @HostListener('click')
  async onclick() {
    let user;
    SignInWithApple.Authorize().then(async res => {
      if (res.response && res.response.identityToken) {
        const provider = new auth.OAuthProvider('apple.com');
        // Create sign in credentials with our token
        const credential = provider.credential({
          idToken: res.response.identityToken
        });
        // Call the sign in with our created credentials
        const userCredential = await this.afAuth.auth.signInWithCredential(credential);
      } else {
        this.presentAlert();
      }
    }).catch(response => {
      this.presentAlert();
    });


   

    // if (this.platform.is('capacitor')) {
    //   user = await this.nativeGoogleLogin();
    //   this.orderServivce.result=user;
      
    // } else {
      
   
    
      // web but not desktop, for example mobile PWA
      //const credential: any = await this.afAuth.auth.signInWithRedirect(provider);

    // }

  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Login Failed',
      message: 'Please try again later',
      buttons: ['OK']
    });
    await alert.present();
  }
}
