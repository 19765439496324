<div style="margin: 20px;">
    <form [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>Number of Copies</mat-label>
            <input matInput type="number" formControlName="printQty">
        </mat-form-field>

        <mat-slide-toggle formControlName="mustConfirm">
            Must Confirm
        </mat-slide-toggle>

        <mat-form-field appearance="fill">
            <mat-label>Printing Option</mat-label>
            <mat-select formControlName="printingOption" (selectionChange)="onPrintingOptionChange()">
                <mat-option *ngFor="let option of printingOptions" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="form.get('printingOption').value === 'Cloud PRNT Multiple'">
            <mat-slide-toggle formControlName="multipleStations">
                Multiple Stations
            </mat-slide-toggle>

            <div *ngIf="form.get('multipleStations').value">
                <div formArrayName="printStations" *ngFor="let station of printStations.controls; let i = index">
                    <mat-form-field appearance="fill">
                        <mat-label>Print Station Name</mat-label>
                        <input matInput [formControlName]="i">
                    </mat-form-field>
                    <button mat-button type="button" (click)="removeStation(i)">Remove</button>
                </div>
                <button mat-button type="button" (click)="addStation()">Add Station</button>
            </div>
        </div>
    </form>
    <button mat-raised-button color="primary" (click)="submitForm()">Submit</button>
</div>