import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReservationService } from '../reservation.service';
import { MatDialog } from '@angular/material/dialog';
import { PreviousReservationComponent } from '../previous-reservation/previous-reservation.component';
import { switchMap } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit, OnDestroy {
  acceptsReservations; 
  reservations = [];
  todaysReservations = [];
  restaurant;
  restaurant$;
  selectedRestaurant
  
  constructor(private route: ActivatedRoute,public dialog: MatDialog,public resService: ReservationService, public orderService: OrderArrayService, public afs: AngularFirestore, private router: Router) { 
    this.selectedRestaurant = this.router.getCurrentNavigation().extras.state.selectedRestaurant;  
    console.log("this.selectedRestaurant: ", this.selectedRestaurant.value.restaurantID)
    this.restaurant$ = this.route.paramMap.pipe(
      switchMap(params => {
        const restaurant = params.get('restaurantID');
        this.restaurant=restaurant;       
        return this.afs.doc('restaurants/' + restaurant).valueChanges();
      }

      )

    ).subscribe();

    this.acceptsReservations = this.selectedRestaurant.value.reservations;

    this.afs.collection(`reservations/${this.selectedRestaurant.value.restaurantID}/upcoming`, ref => ref.orderBy('created', 'desc')).valueChanges({ idField: 'id' }).subscribe(val => {
      this.reservations = val;      
    });

    let start = new Date();
    let end = new Date();

    start.setHours(0, 0, 0, 0);

    end.setHours(23, 59, 0, 0);



    this.afs.collection(`reservations/${this.selectedRestaurant.value.restaurantID}/upcoming`,
      ref => ref
        .where('date', '>=', start)
        .where('date', '<=',end)
        .orderBy('date')
      )
      .valueChanges({ idField: 'id' }).subscribe(val => {
        this.todaysReservations = val;
      });  
    // this.orderService.selectedRestaurant.subscribe(val => {
    //   this.acceptsReservations = val.reservations;
    // });


  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }


  confirmRes(res){
    res.confirmed=true
    this.afs.doc(`reservations/${this.selectedRestaurant.value.restaurantID}/upcoming/${res.id}`).update({
      confirmed: true
    });
  }

  cancelRes(res) {

    if (confirm('Cancel Reservation?')) {
      res.canceled=true
      this.afs.doc(`reservations/${this.selectedRestaurant.value.restaurantID}/upcoming/${res.id}`).update({
        canceled: true
      })
    }


  }

  undoCancelRes(res) {

    if (confirm('Undo Cancel Reservation?')) {
      
      this.afs.doc(`reservations/${this.selectedRestaurant.value.restaurantID}/upcoming/${res.id}`).update({
        canceled: false
      })
    }


  }

  reservationToggle() {
    let txt;
    if (this.acceptsReservations) {
      txt = 'Start accepting reservations?';
    } else {
      txt = 'Stop accepting reservations?';
    }
    if (confirm(txt)) {
      this.afs.doc('restaurants/' + this.selectedRestaurant.value.restaurantID).update({
        reservations: this.acceptsReservations
      });
    } else {

    }

  }
  openPreviousReservation(){
    let dialogRef = this.dialog.open(PreviousReservationComponent, {
      height: '90%',
      minWidth:'400px',
      maxWidth: '700px',
      width: '100%',
      data: {
        selectedRestaurant: this.selectedRestaurant
      }
    });
  }

}
