import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-print-notification-setting',
  templateUrl: './print-notification-setting.component.html',
  styleUrls: ['./print-notification-setting.component.css']
})
export class PrintNotificationSettingComponent implements OnInit {

  form: FormGroup;
  printingOptions = ["Cloud PRNT", "POS Pub", "Cloud PRNT Multiple", "None"];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      printQty: ['', [Validators.required, Validators.min(1)]],
      mustConfirm: [false],
      printingOption: ['', Validators.required],
      multipleStations: [false],
      printStations: this.fb.array([])
    });
  }

  ngOnInit(): void { }

  onPrintingOptionChange() {
    const printingOption = this.form.get('printingOption').value;
    if (printingOption === 'Cloud PRNT Multiple') {
      this.form.get('multipleStations').setValidators(Validators.required);
      this.form.get('printStations').setValidators(Validators.required);
    } else {
      this.form.get('multipleStations').clearValidators();
      this.form.get('printStations').clearValidators();
      this.form.get('multipleStations').setValue(false);
      this.printStations.clear();
    }
    this.form.get('multipleStations').updateValueAndValidity();
    this.form.get('printStations').updateValueAndValidity();
  }

  get printStations(): FormArray {
    return this.form.get('printStations') as FormArray;
  }

  addStation(): void {
    this.printStations.push(this.fb.control(''));
  }

  removeStation(index: number): void {
    this.printStations.removeAt(index);
  }
  submitForm() {
    if (this.form.valid) {
      console.log('Form Data:', this.form.value);
    } else {
      console.log('Form is not valid');
    }
  }

}
