import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { OrderArrayService } from '../order-array.service';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import * as moment from "moment-timezone";

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit, OnDestroy {

  pickupCount = 0;
  deliveryCount = 0;
  restaurantPricing;
  totalTip = 0;
  totalOrder = 0;
  totalFee = 0;
  selectedRestaurant;
  dailySubtotal = 0;
  dailyTotal = 0;
  cancelCount = 0;
  restaurantTotal = 0;
  tax = 0;
  restaurant$;
  restaurant;
  stats;
  checkLoading;
  allOrderArray$;
  archiveOrderArray$;

  statsLoading = false;

  range = new FormGroup({
    start: new FormControl(moment().tz('America/Denver').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })),
    end: new FormControl(moment().tz('America/Denver').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
  });

  constructor(private afs: AngularFirestore, private route: ActivatedRoute, private orderService: OrderArrayService, private router: Router) {
    this.restaurantPricing = router.getCurrentNavigation().extras.state.restaurantPricing;
    this.selectedRestaurant = router.getCurrentNavigation().extras.state.selectedRestaurant;

  }

  ngOnInit() {

    console.log('range', this.range.value)

    this.orderService.globalLoading = true;

    this.restaurant$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.restaurant = params.get('restaurantID');
        this.calculateStats();
        return this.afs.doc('restaurants/' + this.restaurant).valueChanges();
      }

      )

    ).subscribe();

    this.range.valueChanges.pipe(
      debounceTime(1000)
    )
      .subscribe(val => {
        this.calculateStats();
        console.log('val changed', val)
      })

  }

  ngOnDestroy() {
    this.allOrderArray$.unsubscribe();
  }

  async calculateStats() {

    if (this.allOrderArray$) {
      this.allOrderArray$.unsubscribe();
    }
    if (this.archiveOrderArray$) {
      this.archiveOrderArray$.unsubscribe();
    }




    this.statsLoading = true;
    this.orderService.globalLoading = true;
    this.pickupCount = 0
    this.deliveryCount = 0
    this.totalFee = 0
    this.totalTip = 0;
    this.totalOrder = 0;
    this.cancelCount = 0;
    this.dailySubtotal = 0;
    this.dailyTotal = 0;
    this.restaurantTotal = 0;
    this.tax = 0;

    if (!this.range.get('start').value || !this.range.get('start').value) {
      return;
    }




    this.afs.doc(`internal/${this.restaurant}/stats/orderCount`).get().toPromise().then(doc => {
      console.log(doc.data())
      this.stats = doc.data();
    });


    try {

      let start = new Date(this.range.get('start').value);
      let end = new Date(this.range.get('end').value);

      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 9999);


      console.log('start', start)
      console.log('end', end)


      this.allOrderArray$ = await this.afs.collection(`internal/${this.restaurant}/current_orders`,
        ref => ref.where('order_iso_time', '>=', start).where('order_iso_time', '<=', end)).valueChanges().subscribe((snapshots) => {

          snapshots.forEach((doc: any) => {

            if (doc.canceled != true) {
              if (doc.orderType == 'Pickup') {
                this.pickupCount += 1
              } else if (doc.orderType == 'Delivery') {
                this.deliveryCount += 1
              }
              if (doc.orderType != 'Doordash') {
                this.totalTip += doc.tipAmount;
              }
              this.totalFee += doc.convenienceFee
              this.dailyTotal += doc.total;
              this.dailySubtotal += doc.subTotal + doc.discount;
              this.tax += doc.tax;

              if (doc.orderType != 'Doordash') {
                switch (this.restaurantPricing.fee.type) {
                  case "percent":
                    this.restaurantTotal += doc.total - Math.round(this.restaurantPricing.fee.regular * (doc.subTotal + doc.tax) * 100) / 100 - (doc.total * .029) - .30;
                    break;

                  case "amount":
                    this.restaurantTotal += doc.total - this.restaurantPricing.fee.regular - (doc.total * .029) - .30;
                    break;

                  default:
                    break;
                }
              }
              else {
                switch (this.restaurantPricing.fee.type) {
                  case "percent":
                    this.restaurantTotal += doc.total - Math.round(this.restaurantPricing.fee.regular * (doc.subTotal + doc.tax) * 100) / 100 - (doc.total * .029) - .30 - doc.tipAmount - this.selectedRestaurant.value.doordashFee;
                    break;

                  case "amount":
                    this.restaurantTotal += doc.total - this.restaurantPricing.fee.regular - (doc.total * .029) - .30 - doc.tipAmount - this.selectedRestaurant.value.doordashFee;
                    break;

                  default:
                    break;
                }
              }

            }

            if (doc.canceled) {
              this.cancelCount++;
              console.log("Doordash Fee: ", this.selectedRestaurant.value.doordashFee)
              if (doc.orderType == 'Doordash' && doc.deliveryStatus != "scheduled" && doc.deliveryStatus != "unassigned") {
                console.log("got in here")
                this.restaurantTotal -= (doc.tipAmount + this.selectedRestaurant.value.doordashFee)
              }
            }

            this.totalOrder += 1;
          });
        });


      this.archiveOrderArray$ = await this.afs.collection(`internal/${this.restaurant}/order_archive`,
        ref => ref.where('order_iso_time', '>', start).where('order_iso_time', '<', end)).valueChanges().subscribe((snapshots) => {
          snapshots.forEach((doc: any) => {

            console.log(doc);

            if (doc.canceled !== true) {
              if (doc.orderType == 'Pickup') {
                this.pickupCount += 1
              } else if (doc.orderType == 'Delivery') {
                this.deliveryCount += 1
              }
              if (doc.orderType != 'Doordash') {
                this.totalTip += doc.tipAmount;
              }
              this.totalFee += doc.convenienceFee
              this.dailyTotal += doc.total;
              this.dailySubtotal += doc.subTotal + doc.discount;;
              this.tax += doc.tax;
              if (doc.orderType != 'Doordash') {
                switch (this.restaurantPricing.fee.type) {
                  case "percent":
                    this.restaurantTotal += doc.total - Math.round(this.restaurantPricing.fee.regular * (doc.subTotal + doc.tax) * 100) / 100 - (doc.total * .029) - .30;
                    break;

                  case "amount":
                    this.restaurantTotal += doc.total - this.restaurantPricing.fee.regular - (doc.total * .029) - .30;
                    break;

                  default:
                    break;
                }
              }
              else {
                switch (this.restaurantPricing.fee.type) {
                  case "percent":
                    this.restaurantTotal += doc.total - Math.round(this.restaurantPricing.fee.regular * (doc.subTotal + doc.tax) * 100) / 100 - (doc.total * .029) - .30 - doc.tipAmount - this.selectedRestaurant.value.doordashFee;
                    break;

                  case "amount":
                    this.restaurantTotal += doc.total - this.restaurantPricing.fee.regular - (doc.total * .029) - .30 - doc.tipAmount - this.selectedRestaurant.value.doordashFee;
                    break;

                  default:
                    break;
                }
              }
            }

            if (doc.canceled) {
              this.cancelCount++;
              if (doc.orderType == 'Doordash' && doc.deliveryStatus != "scheduled") {
                this.restaurantTotal -= (doc.tipAmount + this.selectedRestaurant.value.doordashFee)
              }
            }

            this.totalOrder += 1;
          });
          this.orderService.globalLoading = false;
          this.statsLoading = false;
        });



    } catch (error) {

      alert('Database Error' + error);
      this.orderService.globalLoading = false;
      this.statsLoading = false;

    }

    this.totalTip = parseFloat((this.totalTip.toFixed(2)));




  }

}
