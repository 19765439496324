import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { OrderArrayService } from '../order-array.service';
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EditTimeMenuComponent } from '../edit-time-menu/edit-time-menu.component';
import { EditNotesMenuComponent } from '../edit-notes-menu/edit-notes-menu.component';
import { PrintNotificationSettingComponent } from '../print-notification-setting/print-notification-setting.component';
import { HolidayListComponent } from '../holiday-list/holiday-list.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  //initialize map for edit delivery area
  @ViewChild(GoogleMap, { static: false }) private set _googleMap(
    map: GoogleMap
  ) {
    if (map && (this.polygonCalled == false)) {
      this.polygonCalled = true;
      console.log('this.coords', this.coords)
      this.polygon = new google.maps.Polygon({
        map: map.googleMap,
        paths: this.coords.map(c => ({
          lat: c.lat,
          lng: c.lng
        })),
        strokeColor: `#caa052`,
        // editable: true,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: `#ffffb1`,
        fillOpacity: 0.35,
        // draggable: true
      });

    }
  }

  polygon: google.maps.Polygon;
  polygonCalled = false;
  deliveryAreaToggle = false;
  mapOptions: any;
  coords: any[] = [];
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  storeCoordinate: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  zoom = 10;
  vertices = [];


  selectedRestaurant;
  restaurant$;
  restaurant;
  deliveryETA;
  pickupETA;
  restaurantName;
  closedMessageDisabled = true;
  closedMessageValue;
  result;
  checked;
  takesDelivery;
  deliveryTimes = [
    '30 mins',
    '40 mins',
    '50 mins',
    '60 mins',
    '70 mins',
    '80 mins',
    '90 mins',
    '120+ mins'

  ];
  pickupTimes = [
    '15 mins',
    '20 mins',
    '30 mins',
    '40 mins',
    '45 mins',
    '50 mins',
    '60 mins',
    '70 mins',
    '80 mins',
    '90 mins',
    '120+ mins'
  ];
  constructor(public dialog: MatDialog, private afs: AngularFirestore, private route: ActivatedRoute, private fns: AngularFireFunctions, public orderService: OrderArrayService, private router: Router) {

    console.log('user', this.orderService.user)

    this.selectedRestaurant = this.router.getCurrentNavigation().extras.state.selectedRestaurant;
    this.restaurant$ = this.route.paramMap.pipe(
      switchMap(async params => {
        const restaurant = params.get('restaurantID');
        this.restaurant = restaurant;
        await this.loadComponentData();
        return this.afs.doc('restaurants/' + restaurant).valueChanges();
      }

      )

    ).subscribe();

  }
  openNoteEdit() {
    this.dialog.open(EditNotesMenuComponent, {
      width: '100vw',
      height: '70vh',
      data: { selectedRestaurant: this.selectedRestaurant }
    });
  }
  openHolidayEdit() {
    this.dialog.open(HolidayListComponent, {
      width: '100vw',
      height: '70vh',
      data: { selectedRestaurant: this.selectedRestaurant }
    });
  }
  openTimeEdit() {
    this.dialog.open(EditTimeMenuComponent, {
      width: '100vw',
      height: '70vh',
      data: { selectedRestaurant: this.selectedRestaurant }
    });
  }
  openPrintNotic() {
    this.dialog.open(PrintNotificationSettingComponent, {
      width: '100vw',
      height: '70vh',
      data: { selectedRestaurant: this.selectedRestaurant }
    });
  }

  enableAreaEdit() {
    this.polygon.setDraggable(true);
    this.polygon.setEditable(true);
  }

  confirmAreaEdit() {
    this.polygon.setDraggable(false);
    this.polygon.setEditable(false);
    let nodes = this.polygon.getPath()
    this.vertices = [];
    for (let i = 0; i < nodes.getLength(); i++) {
      const xy = nodes.getAt(i);
      let V = { lat: xy.lat(), lng: xy.lng() }
      this.vertices.push(V);
    }
    console.log("this.vertices: ", this.vertices)
    this.afs.doc('restaurants/' + this.restaurant).update({
      deliveryArea: this.vertices
    });
  }

  async loadComponentData() {

    let docRef = await this.afs.doc('restaurants/' + this.restaurant).valueChanges().subscribe((doc: any) => {
      this.deliveryETA = doc.deliveryTime;
      this.pickupETA = doc.pickupTime;
      this.checked = doc.acceptingOrders;
      this.takesDelivery = doc.takesDelivery
      this.restaurantName = doc.displayName;
      this.closedMessageValue = doc.closedMessage;

      if (doc.deliveryAreaToggle) {
        this.deliveryAreaToggle = doc.deliveryAreaToggle;
        if (this.deliveryAreaToggle == true) {
          this.storeCoordinate = doc.storeCoordinate;
          this.center = this.storeCoordinate;
          this.mapOptions = {
            center: this.center,
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true
          };
          if (doc.deliveryArea) {
            console.log('assign coords')
            this.coords = doc.deliveryArea
          } else {
            console.log('assign coords')
            this.coords = [
              { lat: this.center.lat - 0.001, lng: this.center.lng - 0.001, },
              { lat: this.center.lat - 0.001, lng: this.center.lng + 0.001, },
              { lat: this.center.lat + 0.001, lng: this.center.lng + 0.001, },
              { lat: this.center.lat + 0.001, lng: this.center.lng - 0.001, },
            ]
          }
          this.polygon.setPaths(this.coords.map(c => ({
            lat: c.lat,
            lng: c.lng
          })))
        }
      }
    });
  }

  ngOnInit() {
  }

  isManager() {
    return true;
  }
  deliveryTimeChange(e) {
    this.afs.doc('restaurants/' + this.restaurant).update({
      deliveryTime: e.value
    });
  }
  pickupTimeChange(e) {
    this.afs.doc('restaurants/' + this.restaurant).update({
      pickupTime: e.value
    });
  }

  activationChange() {
    let txt;
    if (this.checked) {
      txt = 'Are you sure you want to activate orders? Users will be able to place orders.'
        ;
    } else {
      txt = 'Are you sure you want to deactivate orders? Users will not be able to make orders. '
    }
    if (confirm(txt)) {
      this.selectedRestaurant.value.acceptingOrders = this.checked;
      this.afs.doc('restaurants/' + this.restaurant).update({
        acceptingOrders: this.checked
      });
    } else {

    }

  }
  deliveryChange() {
    let txt;
    if (this.takesDelivery) {
      txt = 'Are you sure you want to activate Delivery? Users will be able to place Delivery orders.'
        ;
    } else {
      txt = 'Are you sure you want to deactivate Delivery? Users will not be able to make Delivery orders. '
    }
    if (confirm(txt)) {
      this.afs.doc('restaurants/' + this.restaurant).update({
        takesDelivery: this.takesDelivery
      });
    } else {

    }
  }

  deliveryAreaSwitch() {
    let txt;
    if (this.deliveryAreaToggle) {
      txt = 'Are you sure you want to activate Delivery Area? Users will only be able to place Delivery orders if the address is in the zone.'
        ;
    } else {
      txt = 'Are you sure you want to deactivate Delivery Area? Users will be able to make Delivery orders base on the distance.'
    }
    if (confirm(txt)) {
      this.afs.doc('restaurants/' + this.restaurant).update({
        deliveryAreaToggle: this.deliveryAreaToggle
      });
    } else {

    }
  }

  async updateClosedMessage() {
    await this.afs.doc(`restaurants/${this.restaurant}`).update({
      closedMessage: this.closedMessageValue
    });

    this.closedMessageDisabled = true;
  }

}
