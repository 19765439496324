import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatsComponent } from './stats/stats.component';
import { OrdersComponent } from './orders/orders.component';
import { AdminComponent } from './admin/admin.component';
import { MenuEditorComponent } from './menu-editor/menu-editor.component';
import { InfoComponent } from './info/info.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { CheckinsComponent } from './checkins/checkins.component';


const routes: Routes = [
  {
    path: '', 
    children:[
      { path: ':restaurantID',component: OrdersComponent },
    ]
  },
    
  {
    path: 'menu',
    children: [
      { path: ':restaurantID', component: MenuEditorComponent }
    ]
  },
  {
    path: 'stats',
    children: [
      { path: ':restaurantID', component: StatsComponent }
    ]
  },
  {
    path: 'admin',
    children: [
      { path: ':restaurantID', component: AdminComponent }
    ]
  },
  {
    path: 'restaurant',
    children: [
      { path: ':restaurantID', component: InfoComponent }
    ]
  },

  {
    path: 'checkins',
    children: [
      { path: ':restaurantID', component: CheckinsComponent }
    ]
  },
  {
    path: 'reservations',
    children: [
      { path: ':restaurantID', component: ReservationsComponent }
    ]
  },

  { path: '**', component: OrdersComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
