import { Directive, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Platform } from '@ionic/angular';
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from './../environments/environment';
import { OrderArrayService } from './order-array.service';
@Directive({
  selector: '[appGoogleSignin]'
})



export class GoogleSigninDirective {
  constructor(public gplus:GooglePlus, private afAuth: AngularFireAuth,public orderServivce:OrderArrayService, public platform: Platform,  private afs: AngularFirestore, private orderServce: OrderArrayService) { }

  claims;

  @HostListener('click')
  async onclick() {
    let user;
    const provider = new auth.GoogleAuthProvider();
    if (this.platform.is('capacitor')) {
      user = await this.nativeGoogleLogin();
      this.orderServivce.result=user;
      
    } else {
   
      try {
        const credential: any = await this.afAuth.auth.signInWithPopup(provider);
      } catch (error) {
        console.log('signIn error: ',error)
      }
      
      // web but not desktop, for example mobile PWA
      //const credential: any = await this.afAuth.auth.signInWithRedirect(provider);

    }

  }

  async nativeGoogleLogin():Promise<any>{
    try { 
      const gplusUser= await this.gplus.login({
      webClientId:"29346191873-n4rsa6kuo3d2f4kali37okj86llmctgs.apps.googleusercontent.com",
      offline:true,
      // scopes:'profile email'

    }).catch((error) => {
      console.log(error)
      alert('error:' + JSON.stringify(error))
    });;
    console.log('afther google plus log in')
    return await this.afAuth.auth.signInWithCredential(
      auth.GoogleAuthProvider.credential(gplusUser.idToken)
    ) 
      
    } catch (error) {
      alert(error)
    }
   
  }


}


