import { Component, Inject, OnInit } from '@angular/core';
import { HolidayFormComponent } from '../holiday-form/holiday-form.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.css']
})
export class HolidayListComponent implements OnInit {
  selectedRestaurant: any
  holidays: any[] = [
    { id: 1, name: 'New Year', date: '2024-01-01', startTime: '00:00', endTime: '23:59' }
    // Add more holidays here
  ];

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private afs: AngularFirestore) {
    this.selectedRestaurant = data.selectedRestaurant;
    this.holidays = this.selectedRestaurant.value.holidayHours
  }
  ngOnInit(): void {

  }

  openDialog(): void {
    this.dialog.open(HolidayFormComponent, {
      width: '250px',
      data: { selectedRestaurant: this.selectedRestaurant } // Optional: Pass data if editing an existing holiday
    });
  }

  deleteHoliday(holidayName: String): void {
    this.holidays = this.holidays.filter(holiday => holiday.name.name !== holidayName);
    this.selectedRestaurant.value.holidayHours = this.holidays
    console.log("this.selectedRestaurant.value", this.selectedRestaurant.value)
    this.afs.doc('restaurants/' + this.selectedRestaurant.value.restaurantID).set(this.selectedRestaurant.value);
  }

}
