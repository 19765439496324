<h2 class="p-l-16 m-t-30 m-b-30">{{'preRes.Previous_Reservations'|translate}}: </h2>
<div *ngIf="ReservationLookupType=='date'">
  <div class="mx-auto">
    <mat-form-field class="w-50 dateField">
      <input matInput [matDatepicker]="picker3" placeholder="{{'preRes.Choose_A_Date'|translate}}" [formControl]="checkedDate"
        (dateChange)="gerDateOrders(); ">
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="tabContainer">
    <div *ngIf="dateLookupArray.length > 0 else noLookupRes" class="m-2 reservatonList">
      <div *ngFor="let res of dateLookupArray" class="p-t-16">
        <h4>{{'preRes.Reservation_For'|translate}}: <b>{{res.date.seconds * 1000 | date:'fullDate'}} {{res.time}}</b></h4>
        <p>{{'preRes.Name'|translate}}: {{res.name}}, {{'preRes.Phone'|translate}}: {{res.phoneNum}}</p>
        <p class="m-b-16">{{'preRes.Comments'|translate}}: <em>{{res.comments}}</em></p>
        <hr>
      </div>
    </div>
    <ng-template #noLookupRes>
      <h2 class="text-center">{{'preRes.No_Reservation_Found'|translate}}. </h2>
    </ng-template>
  </div>
</div>

