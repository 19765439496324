<main *ngIf="!(afAuth.authState | async)" class="loginPage">
  <!-- <aside style="background: url('https://res.cloudinary.com/inhouse-orders/image/upload/v1602110934/Photo_Travel_Postcard_hosmzb.jpg') right center / cover rgb(21, 22, 77)"></aside> -->
 
  <div class="loginSection">
    <div style="width: 100%; margin-bottom: 40px;">
      <img style="width: 250px; margin: auto; padding: 2rem 1rem;" src="../../assets/images/Asset 69io_asset.png"
        alt="Inhouse Orders Logo">
    </div>
    <h1 i18n style="width: 100%">Welcome back!</h1>
    <button mat-raised-button appGoogleSignin class="xlButton">
      <img i18n src="/assets/google.png" /> Login with Google
    </button>
    <!-- <button mat-raised-button appAppleSignin class="xlButton">
      <img src="/assets/apple.png" /> Login with Apple
    </button> -->
  </div>




</main>