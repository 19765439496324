<h3 class="text-center" mat-dialog-title>{{'remove.Remove_Items'|translate}}</h3>
<mat-dialog-content class="mat-typography">

  <div *ngFor="let item of data.order.items; let i = index">
    <div *ngFor='let q of Arr(item.quantity).fill(1);let j =index'>
      <mat-checkbox (change)="calculateDifference($event, i,j, item)" class="example-margin"><b><span>1 x</span> {{ item.name }} </b></mat-checkbox>
    </div>
    <!-- <mat-checkbox (change)="calculateDifference($event, i, item)" class="example-margin"><b><span>{{item.quantity}} x</span> {{ item.name }} </b></mat-checkbox> -->
    <p></p>
    <!-- Start Modifiers -->

    <!-- HARD CODED ITEMS -->
    <p class="indent"><b>{{item.size}}</b></p>
    <!-- Dynamic Modifiers -->
    <p *ngFor="let mod of orderService.modArray" class="indent">
      <b *ngIf="item[mod.name]">
        <span *ngIf="mod.type == 'multi-dropdown'">Add: </span>{{ item[mod.name] }}</b> </p>

    <p class="text-right"> {{ item.price | currency }} </p>
    <hr>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <p><b>{{'remove.New_Total'|translate}}: {{ newTotal | currency }}</b></p>
  <div class="ml-auto">
  <button mat-flat-button mat-dialog-close color="accent" >{{'remove.Cancel'|translate}}</button>
  <button  mat-dialog-close  mat-flat-button *ngIf="newTotal != data.order.total" color="primary" (click)="remove()">{{'remove.Remove_Selected_Items'|translate}}</button></div>
</mat-dialog-actions>
<!-- <pre>{{ data | json }}</pre> -->
