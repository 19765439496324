import { ChangeDetectorRef,Component, OnInit,Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, BehaviorSubject } from 'rxjs';
import { OrderArrayService } from "../order-array.service";
export interface Item { name: string; }

@Component({
  selector: 'app-edit-order-dialog',
  templateUrl: './edit-order-dialog.component.html',
  styleUrls: ['./edit-order-dialog.component.css']
})
export class EditOrderDialogComponent implements OnInit {

  public times: string[] = ['11:00 AM', '11:10 AM', '11:20 AM', '11:30 AM', '11:40 AM', '11:50 AM', '12:00 PM', '12:10 PM', '12:20 PM', '12:30 PM', '12:40 PM', '12:50 PM', '01:00 PM', '01:10 PM', '01:20 PM', '01:30 PM', '01:40 PM', '01:50 PM', '02:00 PM', '02:10 PM', '02:20 PM', '02:30 PM', '02:40 PM', '02:50 PM', '03:00 PM', '03:10 PM', '03:20 PM', '03:30 PM', '03:40 PM', '03:50 PM', '04:00 PM', '04:10 PM', '04:20 PM', '04:30 PM', '04:40 PM', '04:50 PM', '05:00 PM', '05:10 PM', '05:20 PM', '05:30 PM', '05:40 PM', '05:50 PM', '06:00 PM', '06:10 PM', '06:20 PM', '06:30 PM', '06:40 PM', '06:50 PM', '07:00 PM', '07:10 PM', '07:20 PM', '07:30 PM', '07:40 PM', '07:50 PM', '08:00 PM', '08:10 PM', '08:20 PM', '08:30 PM', '08:40 PM', '08:50 PM', '09:00 PM', '09:10 PM', '09:20 PM', '09:30 PM', '09:40 PM', '09:50 PM', '10:00 PM']
  public orderTypes: string[] = ['Pickup', 'Delivery']
  private itemDoc: AngularFirestoreDocument<Item[]>;
  items: Observable<Item[]>;
  public to: string = '';
  date;
  time;
  aptNum;
  orderType;
  loading = false;
  verified = false;
  message: string = '';
  deliveryAddress = '';
  deliveryAddress$ = new BehaviorSubject('');

  constructor(private cd: ChangeDetectorRef, public orderArray: OrderArrayService, private afs: AngularFirestore, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.itemDoc = afs.doc<Item[]>(`internal/${this.data.selectedOrder.restaurantID}/current_orders/${this.data.selectedOrder.id}`);
    this.items = this.itemDoc.valueChanges();
  }

  ngOnInit() {

  }
  updateTime() {

    if (this.time) {
      this.data.selectedOrder.futureOrderTime = this.time;
    }
    if(this.aptNum)
    {
      this.data.selectedOrder.aptNum=this.aptNum
    }
    if (this.date) {
      this.data.selectedOrder.futureOrderDate = this.date.getTime();
    }
    if (this.orderType) {
      this.data.selectedOrder.orderType = this.orderType;
      if (this.orderType=='Pickup') {
        this.data.selectedOrder.deliveryAddress = '';
        this.data.selectedOrder.aptNum='';
      }
    }
    this.data.selectedOrder.deliveryAddress = this.deliveryAddress;
    if (this.deliveryAddress != ""){
      this.data.selectedOrder.deliveryAddress = this.deliveryAddress;
    }
    this.itemDoc.update(this.data.selectedOrder);
  }


  async verifyAddress() {
    //strictly for new address - need to be formatted correctly?
    await this.checkDistance(this.orderArray.addressInput);
  }

  async checkDistance(location_obj) {


    this.loading = true;

    if (!location_obj) {

      this.loading = false;
      return;

    }

    //console.log('type', typeof (location_obj));
    //console.log(location_obj);
    if (typeof (location_obj) != 'string') {

      location_obj = location_obj.formatted_address;

    }
    else {

    }

    let distance: string;
    var origin2 = '1400 E 18th Ave, Denver, CO 80218';
    var destinationA = location_obj;
    let origins = [origin2];

    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: origins,
        destinations: [destinationA],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      }, (response, status) => {
        // See Parsing the Results for
        // the basics of a callback function.
        let destination = '';
        if (status == google.maps.DistanceMatrixStatus.OK) {
          var origins = response.originAddresses;
          var destinations = response.destinationAddresses;

          for (var i = 0; i < origins.length; i++) {
            var results = response.rows[i].elements;
            for (var j = 0; j < results.length; j++) {
              var element = results[j];
              distance = element.distance.text;
              var duration = element.duration.text;
              var from = origins[i];
              this.to = destinations[j];
            }
          }

        }
        // console.log(from);
        //console.log(this.to);
        //console.log(distance);
        // console.log(duration);

        this.checkValidity(distance);
      });
  }
  //returns true or false if address is valid or not
  checkValidity(distance): boolean {
    this.deliveryAddress = this.to;
    var n = distance.includes("ft");
    if (n) {
      this.valid(this.to);
      return true;
    }
    else if (parseFloat(distance) <= 3) {
      this.valid(this.to);

      return true;
    }
    else {
      this.loading = false;
      this.verified = false;
      this.message = '😰 that address is out of range.';
      this.cd.detectChanges();
      return false;
    }
  }
  valid(address) {

    this.deliveryAddress = address;
    this.loading = false;
    this.message = '😁 that address is in range.';
    this.verified = true;

    // this.deliveryAddress$.next(address);
    // this.checkout.setDeliveryAddressVal(address);
    this.cd.detectChanges();

  }


}
