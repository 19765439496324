<div>

    <button mat-button matSuffix mat-icon-button aria-label="Close dialog" ([mat-dialog-close])="false"
        (click)="close()" style="float: right">
        <mat-icon>close</mat-icon>
    </button>
    <!-- New items -->
    <form [formGroup]="tipForm" #addForm="ngForm">

        <mat-form-field class="example-form-field">
            <input type="number" matInput placeholder="{{'add.Tip_Amount'|translate}}" formControlName="amount">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
                (click)="clearTip()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
    <div class="row w-100" *ngIf="tipForm.get('amount').value!= null">
        <button [disabled]=(loading) mat-raised-button class="align-right" color="accent"
            (click)="confirm()">{{'add.Confirm'|translate}}</button>
        <mat-progress-bar mode="indeterminate" color="accent" *ngIf='loading'></mat-progress-bar>
    </div>


    <div class="pretty" *ngIf="tipForm.get('amount').value!= null ">
        <div class=" p-2 tb-fancy-text text-right">
            <p class="added">{{'add.New_Tip'|translate}}: {{ tipForm.get('amount').value | currency }}</p>
        </div>

    </div>

    <div class="row">
        <div class="row col-sm-12 col-md-6 mx-auto p-2 tb-fancy-text text-right">
            <p class="col-6">Subtotal: {{ subTotal | currency }}</p>

            <p class="col-6">Convenience Fee: {{ convenienceFee | currency }}</p>

            <p class="col-6">Tax: {{ tax | currency }}</p>

            <p class="col-6">Tip: {{ tipAmount | currency }}</p>
            <p *ngIf="data.order.type=='doordash'" class="col-6">doordashFee: {{ data.restaurant.doordashFee | currency
                }}</p>

            <p class="col-6 border-top pt-2">Total: {{ total | currency }}</p>

        </div>


    </div>


</div>




<!-- <div *ngIf="confirmed">
    <div class="row d-flex mx-auto col-12">
        <mat-button-toggle-group #paymentOption="matButtonToggleGroup">
            <mat-button-toggle value="original">Original Card Payment</mat-button-toggle>
            <mat-button-toggle value="new">New Card Payment</mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="paymentOption.value == 'new'" class="col-12">
            <app-stripe-payment [amount]="total" [Order]="neworder" [newItem]="addItemForm.value"
                description="Buyyyyyy meeeee!"></app-stripe-payment>
        </div>
        <div class="col-12">
            <button [disabled]=(loading) mat-raised-button class="m-4" color="primary" (click)="ChargeOrigin()">
                {{'add.Confirm'|translate}} </button>
            <mat-progress-bar mode="indeterminate" color="accent" *ngIf='loading'></mat-progress-bar>
        </div>
    </div>
</div> -->