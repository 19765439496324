import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { Style } from '@capacitor/status-bar';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'inhouse-';
  constructor(private deploy: Deploy, private insomnia: Insomnia, private platform: Platform, private splashScreen: SplashScreen,
    private statusBar: StatusBar) {
    this.initializeApp();
    this.insomnia.keepAwake()
      .then(
        () => console.log('success'),
        () => console.log('error')
      );
    // this.performAutomaticUpdate();
    this.deploy.getConfiguration()


    const { SplashScreen, StatusBar } = Plugins;
    try {
      StatusBar.setStyle({ style: Style.Light });
    } catch (err) {
      console.log('This does not have influence on the browser', err);
    }

  }
  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


  // async performAutomaticUpdate() {
  //   console.log('we are about to do update');
  //   try {
  //     const currentVersion = await this.deploy.getCurrentVersion();
  //     console.log('currentVersion: ',currentVersion);
  //     const resp = await this.deploy.sync({ updateMethod: 'auto' }, percentDone => {
  //       console.log(`Update is ${percentDone}% done!`);
  //     });
  //     console.log('resp: ',resp);
  //     if (!currentVersion || currentVersion.versionId !== resp.versionId) {
  //       // We found an update, and are in process of redirecting you since you put auto!
  //       console.log('We found an update, and are in process of redirecting you since you put auto!')
  //     } else {
  //       console.log('No update available: ')
  //       // No update available
  //     }
  //   } catch (err) {
  //     console.log('error: ', err)
  //     // We encountered an error.
  //   }
  // }
  // async changeToBetaChannel() {
  //   await this.deploy.configure({ channel: 'production' });
  // }

  ngOnInit() {
    console.log('something should show belong')
    this.deploy.getConfiguration()

  }

}
