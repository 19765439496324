<div>

    <button mat-button matSuffix mat-icon-button aria-label="Close dialog" ([mat-dialog-close])="false"
        (click)="close()" style="float: right">
        <mat-icon>close</mat-icon>
    </button>
    <!-- New items -->
    <form [formGroup]="addItemForm" #addForm="ngForm">
        <mat-form-field class="example-form-field">
            <input matInput type="text" placeholder="{{'add.Item_Name'|translate}}" formControlName="name">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
                (click)="clearName()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="example-form-field">
            <input type="text" matInput placeholder="{{'add.Item_Price'|translate}}" formControlName="price">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
                (click)="clearPrice()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="example-form-field">
            <input matInput type="text" placeholder="{{'add.Instructions'|translate}}" formControlName="instructions">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
                (click)="clearInstructions()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
    <div class="row w-100" *ngIf="addItemForm.get('name').value!=''">
        <button mat-raised-button class="align-right" color="accent"
            (click)="confirm()">{{'add.Add'|translate}}</button>
    </div>


    <div class="pretty" *ngIf="addItemForm.get('name').value!=''">
        <h4 class="sansSerif ">{{'add.Add_Item'|translate}}:</h4>
        <div class=" p-2 tb-fancy-text text-right">
            <p class="added">{{ addItemForm.get('name').value }}: {{ addItemForm.get('price').value | currency }}</p>
            <p *ngIf="addItemForm.get('instructions').value!=''" class="added">Instruction: {{
                addItemForm.get('instructions').value }}</p>
        </div>

    </div>

    <div class="row">
        <div class="row col-sm-12 col-md-6 mx-auto p-2 tb-fancy-text text-right">
            <p class="col-6">Subtotal: {{ subTotal | currency }}</p>

            <p class="col-6">Convenience Fee: {{ convenienceFee | currency }}</p>

            <p class="col-6">Tax: {{ tax | currency }}</p>

            <p class="col-6">Tip: {{ tipAmount | currency }}</p>
            <p *ngIf="data.restaurant.doordashFee" class="col-6">doordashFee: {{ data.restaurant.doordashFee | currency
                }}</p>

            <p class="col-6 border-top pt-2">Total: {{ total | currency }}</p>

        </div>


    </div>


</div>




<div *ngIf="confirmed">
    <div class="row d-flex mx-auto col-12">
        <!-- <mat-button-toggle-group #paymentOption="matButtonToggleGroup">
            <mat-button-toggle value="original">Original Card Payment</mat-button-toggle>
            <mat-button-toggle value="new">New Card Payment</mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="paymentOption.value == 'new'" class="col-12">
            <app-stripe-payment [amount]="total" [Order]="neworder" [newItem]="addItemForm.value"
                description="Buyyyyyy meeeee!"></app-stripe-payment>
        </div> -->
        <div class="col-12">
            <button [disabled]=(loading) mat-raised-button class="m-4" color="primary" (click)="ChargeOrigin()">
                {{'add.Confirm'|translate}} </button>
            <mat-progress-bar mode="indeterminate" color="accent" *ngIf='loading'></mat-progress-bar>
        </div>
    </div>
</div>