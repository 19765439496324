import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//export interface Item { name: string; }

@Component({
  selector: 'app-new-order-confirm-dialog',
  templateUrl: './new-order-confirm-dialog.component.html',
  styleUrls: ['./new-order-confirm-dialog.component.css']
})
export class NewOrderConfirmDialogComponent implements OnInit {
  public orders;
  //private itemDoc: AngularFirestoreDocument<Item[]>;
  constructor( @Inject(MAT_DIALOG_DATA) public data) { 
    this.orders=data.orders    
  }

  // confirmOrder(selectedOrder){    
  //   this.itemDoc = this.afs.doc<Item[]>(selectedOrder.restaurantID +'_currentOrders/'+selectedOrder.id);
  //   selectedOrder.confirmed=true;
  //   this.itemDoc.update(selectedOrder);
  //   selectedOrder.confirmed=false;
  //   this.orders.remove(selectedOrder);
  // }

  ngOnInit(): void {
  }

}
