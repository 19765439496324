<div class="m-l-20 m-r-20 m-b-20 card">
  <mat-card class="m-1">
    <div class="m-t-20 m-l-20 m-b-20">
      <mat-slide-toggle (change)="reservationToggle()" class="example-margin" color="accent" labelPosition="before"
        [(ngModel)]="acceptsReservations">
        <span *ngIf="acceptsReservations == true">{{'reservation.Accepting_Reservations'|translate}}</span><span
          *ngIf="!acceptsReservations">
          {{'reservation.Not_Accepting_Reservations'|translate}}</span>
      </mat-slide-toggle>
    </div>
  </mat-card>

  <div class="w-100 flex-sa">

    <div>
      <h2 class="p-l-16">{{'reservation.Upcoming_Reservations'|translate}}: </h2>
      <div class="m-2 reservatonList">
        <div *ngFor="let res of this.reservations" class="p-t-16">
          <h4><b>{{res.date.seconds * 1000 | date:'fullDate'}} {{res.time}}</b></h4>
          <p>{{res.guestCount}} {{'reservation.Guests'|translate}}</p>
          <p class="m-t-12">{{'reservation.Name'|translate}}: {{res.name}}, Phone: {{res.phoneNum}}</p>
          <p class="m-b-16">{{'reservation.Comments'|translate}}: <em>{{res.comments}}</em></p>
          <p class="fs-10"><em>{{'reservation.please_call'|translate}}. </em></p>

          <div class="m-t-8 m-b-8">
            <button mat-button color="warn" *ngIf="!res.confirmed" class="" (click)="cancelRes(res)">{{'reservation.Cancel'|translate}}</button>
            <button mat-stroked-button color="primary" *ngIf="!res.confirmed" class="" (click)="confirmRes(res)">{{'reservation.send_email'|translate}}</button>
            <div class="flex-sb" *ngIf="res.confirmed && !res.canceled">
              <button mat-button color="warn" class="" (click)="cancelRes(res)">{{'reservation.Cancel'|translate}}</button>
              <p class="p-t-6 txt-right">{{'reservation.Confirmed'|translate}} ✅ </p>
            </div>
            <div class="flex-sb" *ngIf="res.canceled">
              <p class="p-t-6 text-warn">{{'reservation.Reservation_was_Canceled'|translate}} </p>
              <button mat-button (click)="undoCancelRes(res)">{{'reservation.Undo'|translate}}</button>
            </div>

          </div>
          <hr>
        </div>
        <p *ngIf=" this.reservations.length == 0">{{'reservation.No_upcoming_reservations'|translate}}. </p>

      </div>
    </div>

    <div class="m-l-8">
      <h2 class="p-l-16">{{'reservation.Today'|translate}}: </h2>

      <div  class="m-2 reservatonList">
        <div *ngFor="let res of this.todaysReservations" class="p-t-16">
          <h4><b>{{res.date.seconds * 1000 | date:'fullDate'}} {{res.time}}</b></h4>
          <p>{{res.guestCount}} {{'reservation.Guests'|translate}}</p>
          <p class="m-t-12">{{'reservation.Name'|translate}}: {{res.name}}, Phone: {{res.phoneNum}}</p>
          <p class="m-b-16">{{'reservation.Comments'|translate}}: <em>{{res.comments}}</em></p>
          <p class="fs-10"><em>{{'reservation.please_call'|translate}}. </em></p>

          <div class="m-t-8 m-b-8">
            <button mat-button color="warn" *ngIf="!res.confirmed" class="" (click)="cancelRes(res)">{{'reservation.Cancel'|translate}}</button>
            <button mat-stroked-button color="primary" *ngIf="!res.confirmed" class="" (click)="confirmRes(res)">{{'reservation.send_email'|translate}}</button>
            <div class="flex-sb" *ngIf="res.confirmed && !res.canceled">
              <button mat-button color="warn" class="" (click)="cancelRes(res)">Cancel</button>
              <p class="p-t-6 txt-right">{{'reservation.Confirmed'|translate}} ✅ </p>
            </div>
            <div class="flex-sb" *ngIf="res.canceled">
              <p class="p-t-6 text-warn">{{'reservation.Reservation_was_Canceled'|translate}} </p>
              <button mat-button (click)="undoCancelRes(res)">{{'reservation.Undo'|translate}}</button>
            </div>

          </div>
          <hr>
        </div>
        <p *ngIf=" this.todaysReservations.length == 0">{{'reservation.No_upcoming_reservations'|translate}}. </p>
      </div>
    </div>
  </div>

  <button mat-flat-button class="mx-1 accent w-100 m-4" color="primary" (click)="openPreviousReservation()">{{'reservation.View_Previous'|translate}}</button>
  <!-- <h2 class="p-l-16 m-t-30">Previous Reservations: </h2>
    <div class="m-2 reservatonList">
      <div *ngFor="let res of this.resService.prevReservations" class="p-t-16">
        <h4>Reservation For: <b>{{res.date.seconds * 1000 | date:'short'}} {{res.time}}</b></h4>
        <p>Name: {{res.name}}, Phone: {{res.phoneNum}}</p>
        <p class="m-b-16">Comments: <em>{{res.comments}}</em></p>
        <hr>
      </div>
    </div> -->
</div>