import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { orderObject, Restaurant } from '../interface';
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { Howl } from 'howler';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable,combineLatest  } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrdersComponent implements OnInit {

  public condition;
  restaurant$;
  inhouseConfig$;
  restaurant;
  confirmation;
  public currentOrders$;
  public allOrderArray: Array<orderObject> = []; // master
  public futureOrderArray: Array<orderObject> = []; //only future orders
  public completeOrderArray: Array<orderObject> = []; //only future orders
  modArray$;
  orderCount = {};
  audioSelection;
  inhouseConfig;
  checkedDate;
  statDate;
  start;
  end;
  OrderLookupType = 'date';
  allOrderArray$;
  archiveOrderArray$;
  dateLookupArray = [];
  lookupArray = [];
  historyArray = [];
  loadArray = [];

  public modArray = []; //modifiers
  range = new FormGroup({
    start: new FormControl(moment().tz('America/Denver').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })),
    end: new FormControl(moment().tz('America/Denver').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
  });
  user: any;
  defaultRestaurantId: any;
  restaurantArray: any[];
  selectedRestaurant;

  viewReady = false;


  constructor(private cd: ChangeDetectorRef, public afAuth: AngularFireAuth, private router: Router, private afs: AngularFirestore, private route: ActivatedRoute, public orderService: OrderArrayService, public dialog: MatDialog, public functions: AngularFireFunctions) {
    // this.selectedRestaurant = router.getCurrentNavigation().extras.state.selectedRestaurant.value;
    // console.log("this.selectedRestaurant",this.selectedRestaurant)



    this.restaurant$ = this.route.paramMap.pipe(
      switchMap(async params => {
        console.log('in the constructor')
        const restaurant = params.get('restaurantID');
        console.log('restaurant: ', restaurant)
        this.restaurant = restaurant;
        if (restaurant) {
          await this.switchRestaurant(restaurant);
        }
        // else {
        //   for (let innerMap in this.user.restaurants) {
        //     if (this.user.restaurants[innerMap]['default'] == true) {

        //       this.defaultRestaurantId = this.user.restaurants[innerMap]['restaurantId'];
        //       await this.switchRestaurant(this.defaultRestaurantId);

        //     }
        //   }
        // }

        this.loadModifiers()
        this.loadOrderArrays()
        return this.afs.doc('restaurants/' + restaurant).valueChanges();
      }

      )

    ).subscribe();








    this.condition = 0;



  }


  ngOnInit() {
    this.statDate = new FormControl(new Date());
    this.start = new FormControl(new Date());
    this.end = new FormControl(new Date());
    this.checkedDate = new FormControl(new Date());
    this.end.value.setDate(this.start.value.getDate() + 1);
    // if (this.restaurant$) {
    //   this.restaurant$.unsubscribe();
    // }




  }
  ngOnDestroy() {


    console.log("destroy is called")
  }


  public async switchRestaurant(rId: string) {
    // this.restaurant$.unsubscribe();
    // this.currentOrders$.unsubscribe();
    // if (this.restaurant$) {
    //   this.restaurant$.unsubscribe();
    // }
    if (this.currentOrders$) {
      this.currentOrders$.unsubscribe();
    }
    let configRef = this.afs.doc('inhouse_config/' + rId).valueChanges().subscribe((doc: any) => {
      this.inhouseConfig = doc;
      if (this.inhouseConfig.audioSelection == 'Asian') {
        this.audioSelection = 0;
      }
      else {
        this.audioSelection = 1;
      }
      this.viewReady = true;

    });

    let resDocRef = this.afs.doc('restaurants/' + rId).valueChanges().subscribe((doc: any) => {
      this.selectedRestaurant = doc;
    });

  }
  public async loadOrderArrays() {
    if (this.currentOrders$) {
      this.currentOrders$.unsubscribe();
      console.log('currentOrders unsubscribe!')

    }
    console.log('loading the array')
    console.log(`internal/${this.restaurant}/current_orders`)

    this.currentOrders$ = await this.afs.collection(`internal/${this.restaurant}/current_orders`, ref => {
      let query: firebase.firestore.Query = ref;
      { query = query.orderBy("order_iso_time", "desc") };
      return query;
    }).valueChanges().subscribe((val: Array<orderObject>) => {

      console.log('loading in the orders', val)
      this.allOrderArray = [];
      this.futureOrderArray = [];
      this.allOrderArray = val.filter(doc => (doc.goTime == true && !doc.ready));
      this.completeOrderArray = val.filter(doc => (doc.goTime == true && doc.ready == true));
      this.allOrderArray.sort(function (a, b) {
        if (a.isFutureOrder && b.isFutureOrder) {
          if (a.futureOrderDateTime < b.futureOrderDateTime) {
            return 1;
          }
          else {
            return -1
          }
        }
        else if (!a.isFutureOrder && b.isFutureOrder) {
          if (a.order_iso_time < b.futureOrderDateTime) {
            return 1;
          }
          else if (a.order_iso_time > b.futureOrderDateTime) {
            return -1
          }
          else {
            return 0
          }
        }
        else if (a.isFutureOrder && !b.isFutureOrder) {
          if (a.futureOrderDateTime < b.order_iso_time) {
            return 1;
          }
          else if (a.futureOrderDateTime > b.order_iso_time) {
            return -1
          }
          else {
            return 0
          }
        }
        else {
          if (a.order_iso_time < b.order_iso_time) {
            return 1;
          }
          else if (a.order_iso_time > b.order_iso_time) {
            return -1
          }
          else {
            return 0
          }
        }
      })
      this.futureOrderArray = val.filter(doc => doc.goTime == false);
      console.log('allOrderArray： ', this.allOrderArray)
      this.orderService.selectedOrder = this.allOrderArray[0];
      console.log('this.orderService.selectedOrder： ', this.orderService.selectedOrder)

      //get the unconfirmed Orders

      if (this.orderCount[this.restaurant]) {
        if ((this.allOrderArray.length > this.orderCount[this.restaurant].currentCount)) {


          //there should be Order Number and name in the diaglog
          //if there are multiple orders should have a array of those orders pass into the dialog
          //there should a way to tell whether the orders are comfirm or not----probably a field in the order object name "confirmed"
          //the audio should keep playing when there is order not being take care of (confirm).
          this.playAudio();
          this.orderCount[this.restaurant] = {
            currentCount: this.allOrderArray.length,
            futureCount: this.futureOrderArray.length
          }

          console.log('currentorder array: ', this.allOrderArray)
          console.log('futureOrderArray: ', this.futureOrderArray)
        }
      }
      else {
        this.orderCount[this.restaurant] = {
          currentCount: this.allOrderArray.length,
          futureCount: this.futureOrderArray.length
        }
      }

      console.log("this.orderCount", this.orderCount)
      if (this.inhouseConfig.mustConfirm) {
        this.orderService.unconfirmedOrders = [];

        for (const index in this.allOrderArray) {
          if (!this.allOrderArray[index].confirmed) {
            this.orderService.unconfirmedOrders.push(this.allOrderArray[index]);
          }
        }
        if (this.orderService.unconfirmedOrders.length != 0) {
          this.orderService.stopNotify();
          this.orderService.startNotify();
          let orders = this.orderService.unconfirmedOrders
          //open the dialog with the the unconfirm orders list


        }
        if (this.orderService.unconfirmedOrders.length == 0) {
          this.orderService.stopNotify()
        }

      }
      else {
        this.orderService.unconfirmedOrders = [];
        this.orderService.stopNotify()
      }
    });
  }
  public async loadModifiers() {

    this.modArray$ = await this.afs.collection(`public/modifiers/${this.restaurant}`).valueChanges().subscribe(val => {
      this.modArray = [];
      val.forEach((doc: any) => {
        this.modArray.push(doc);

      });

    });


  }
  // startNotify() {
  //   this.interval = setInterval(() => {
  //     this.playNotification()
  //   }, 5000);
  // }
  // stopNotify() {

  //   if (this.unconfirmedOrders.length == 0) {
  //     clearInterval(this.interval)
  //   }

  // }

  // playNotification() {
  //   console.log('Notification in the order component');
  //   var sound = new Howl({
  //     src: ['../assets/audio/swiftly.mp3']
  //   });
  //   sound.play();
  // }
  playAudio() {
    console.log('Audio in the order component');
    let path = '';
    switch (this.audioSelection) {
      case 0:
        path = '../assets/audio/alarm.mp3'
        break;

      case 1:
        path = '../assets/audio/swiftly.mp3'


    }

    var sound = new Howl({
      src: [path]
    });
    sound.play();
  }
  async gerDateOrders() {

    this.orderService.globalLoading = true;
    this.dateLookupArray = [];

    try {

      // console.log(this.date.value.getDate());
      let previousDate = new Date(this.checkedDate.value)
      let currentDate = new Date(this.checkedDate.value)

      let startTime = previousDate.setHours(0, 0, 0, 0);
      let endTime = currentDate.setHours(23, 59, 0, 0);

      this.allOrderArray$ = await this.afs.collection(`internal/${this.restaurant}/current_orders`,
        ref => ref.where('order_iso_time', '>', new Date(startTime)).where('order_iso_time', '<', new Date(endTime))).valueChanges();

      this.archiveOrderArray$ = await this.afs.collection(`internal/${this.restaurant}/order_archive`,
        ref => ref.where('order_iso_time', '>', new Date(startTime)).where('order_iso_time', '<', new Date(endTime))).valueChanges();

      let totalOrder$ = combineLatest (this.allOrderArray$, this.archiveOrderArray$)
      totalOrder$.subscribe(([allOrderArray,archiveOrderArray]:Array<any>) => {
            // snapshots.forEach((doc: any) => {
            //   this.historyArray.push(doc);
            // });
            console.log('this.allOrderArray:',allOrderArray);
            console.log('this.archiveOrderArray:',archiveOrderArray);
          this.dateLookupArray=archiveOrderArray.concat(allOrderArray); 
          this.dateLookupArray = this.dateLookupArray.sort(this.compare);
          this.orderService.globalLoading = false;
        });

    } catch (error) {

      alert('Database Error' + error);
      this.orderService.globalLoading = false;
    }

  }




  async getOrders() {
    this.orderService.globalLoading = true;
    
    this.historyArray = [];
    console.log('getting order');

    try {
      // console.log(this.date.value);
      // console.log(this.date.value.getDate());


      let start = new Date(this.range.get('start').value);
      let end = new Date(this.range.get('end').value);
      console.log("start: ", start);
      console.log("end: ", end);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 9999);

      this.archiveOrderArray$ = this.afs.collection(`internal/${this.restaurant}/order_archive`,
        ref => ref.where('order_iso_time', '>', start).where('order_iso_time', '<', end)).valueChanges();
      this.allOrderArray$ = this.afs.collection(`internal/${this.restaurant}/current_orders`,
        ref => ref.where('order_iso_time', '>', start).where('order_iso_time', '<', end)).valueChanges();

      let totalOrder$ = combineLatest (this.allOrderArray$, this.archiveOrderArray$)
      totalOrder$.subscribe(([allOrderArray,archiveOrderArray]:Array<any>) => {
          // snapshots.forEach((doc: any) => {
          //   this.historyArray.push(doc);
          // });
          console.log('this.allOrderArray:',allOrderArray);
          console.log('this.archiveOrderArray:',archiveOrderArray);
          this.historyArray=archiveOrderArray.concat(allOrderArray); 
          this.historyArray=this.historyArray.sort(this.compare);
          console.log('this.historyArray:',this.historyArray);
          this.orderService.globalLoading = false;
        });




      

    } catch (error) {

      alert('Database Error' + error);
      this.orderService.globalLoading = false;
    }

  }
  compare(a, b) {
    if (a.order_iso_time.seconds < b.order_iso_time.seconds) {
      return 1;
    }
    return -1;
  }

  setType(e) {
    this.OrderLookupType = e.value;
  }

  lookupOrdersSelect(i) {
    console.log('trigger lookup')
    this.orderService.selectedOrder = this.lookupArray[i];
    this.condition = i;
    console.log('this.orderService.selectedOrder: ', this.orderService.selectedOrder)
  }

  dateLookupOrdersSelect(i) {
    console.log('trigger dateLookupOrdersSelect')
    this.orderService.selectedOrder = this.dateLookupArray[i];
    this.condition = i;
    console.log('this.orderService.selectedOrder: ', this.orderService.selectedOrder)
  }

  allOrdersSelect(i) {
    console.log("i: ", i)
    this.orderService.selectedOrder = this.allOrderArray[i];
    this.condition = i;
  }

  // currentOrdersSelect(i) {
  //   // console.log("i: ",i)
  //   this.orderService.selectedOrder = this.orderService.nowOrderArray[i];
  //   this.condition = i;
  // }
  futureOrdersSelect(i) {
    // console.log("i: ",i)
    this.orderService.selectedOrder = this.futureOrderArray[i];
    this.condition = i;
  }

  completedOrdersSelect(i) {
    // console.log("i: ",i)
    this.orderService.selectedOrder = this.completeOrderArray[i];
    this.condition = i;
  }

  updateItemDetail(e) {
    console.log('updateItemDetail Trigger!')
    if (e == 0) {
      this.orderService.selectedOrder = this.allOrderArray[0];
    }
    else if (e == 1) {
      this.orderService.selectedOrder = this.futureOrderArray[0];
    }
    console.log(this.orderService.selectedOrder)
  }


}

