import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subject, Subscriber, Subscription, BehaviorSubject } from 'rxjs';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderObject, Restaurant } from './interface';
import { MatDialog } from '@angular/material/dialog';
import { NewOrderConfirmDialogComponent } from './new-order-confirm-dialog/new-order-confirm-dialog.component';
export interface Item { name: string; }

@Injectable({
  providedIn: 'root'
})
export class OrderArrayService {
  currentOrderBadge: string;
  futureOrderBadge: string;
  public result;
  interval;
  confirmation;
  audioSelection;
  restaurantPricing;
  currentCount = 0;
  futureCount = 0;
  public expendedOrders=[];
  selectedRestaurant: BehaviorSubject<Restaurant> = new BehaviorSubject(<Restaurant>{});
  defaultRestaurantId: string;
  public subscription;
  selectedOrderIndex = 0;
  error: any;
  user$: Observable<any>;
  public user;
  public selectedOrder: orderObject;
  isAD = false;
  public globalLoading = false;
  addressInput;
  public currentOrders$;
  modArray$;
  public oldLength = 0;
  public oldfutureLength = 0;
  public unconfirmedOrders: any = [];
  // public allOrderArray: Array<orderObject> = []; // master
  //public nowOrderArray: Array<orderObject> = [];  // only "now" orders
  // public futureOrderArray: Array<orderObject> = []; //only future orders
  viewReady = false;

  restaurantArray = [];

  public modArray = []; //modifiers


  orderObs;

  constructor(private _snackBar: MatSnackBar,public dialog: MatDialog, private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router, public functions: AngularFireFunctions) {

    this.orderObs = this.afs.collection('internal/thaiBasil/current_orders');

  }

  // public async switchRestaurant(rId: string) {
  //   this.restaurantArray = [];
  //   console.log('got into the restaurants')
  //   for (let innerMap in this.user.restaurants) {
  //     let ref = this.afs.doc('restaurants/' + innerMap);
  //     let data = await ref.get();
  //     let docData = (await data.toPromise()).data();
  //     this.restaurantArray.push(docData);
  //   }

  //   await this.selectedRestaurant.next(this.restaurantArray.find(r => r.restaurantID == rId));
  //   this.restaurantPricing = await (await this.afs.doc(`restaurants_secret/${this.selectedRestaurant.value.restaurantID}`).get().toPromise()).data();
  //   let configRef = this.afs.doc('inhouse_config/' + this.selectedRestaurant.value.restaurantID).valueChanges().subscribe((doc: any) => {
  //     console.log('started to load info about the restaurant')
  //     this.inhouseConfig = doc;
  //     console.log('this.inhouseConfig: ',this.inhouseConfig)
  //     if (this.inhouseConfig.audioSelection == 'Asian') {
  //       this.audioSelection = 0;
  //     }
  //     else {
  //       this.audioSelection = 1;
  //     }
  //     this.viewReady = true;

  //   });
  //   this.router.navigate(['',this.selectedRestaurant.value.restaurantID])
  //   // await this.loadOrderArrays();
  //   // await this.loadModifiers();

  // }

  // public async loadOrderArrays() {
  //   if (this.currentOrders$) {
  //     this.currentOrders$.unsubscribe();

  //   }

  //   this.currentOrders$ = await this.afs.collection(`internal/${this.selectedRestaurant.value.restaurantID}/current_orders`, ref => {
  //     let query: firebase.firestore.Query = ref;
  //     { query = query.orderBy("order_iso_time", "desc") };


  //     return query;
  //   }).valueChanges().subscribe((val: Array<orderObject>) => {
  //     console.log('loading in the orderArray service')
  //     this.allOrderArray = [];
  //     this.futureOrderArray = [];
  //     //this.nowOrderArray = [];
  //     this.allOrderArray = val.filter(doc => doc.goTime == true);
  //     this.futureOrderArray = val.filter(doc => doc.goTime == false);
  //     //this.nowOrderArray = val.filter(doc=>doc.isFutureOrder==false);
  //     this.selectedOrder = this.allOrderArray[0];


  //     //get the unconfirmed Orders
  //     if ((this.allOrderArray.length > this.currentCount)) {

  //       if (this.inhouseConfig.mustConfirm) {
  //         // this.unconfirmedOrders = [];

  //         for (const index in this.allOrderArray) {
  //           if (!this.allOrderArray[index].confirmed) {
  //             // this.unconfirmedOrders.push(this.allOrderArray[index]);
  //           }
  //         }

  //         // if (this.unconfirmedOrders.length != 0) {
  //         //  this.startNotify();
  //         //   let orders = this.unconfirmedOrders
  //         //   //open the dialog with the the unconfirm orders list


  //         // }

  //       }
  //       //there should be Order Number and name in the diaglog
  //       //if there are multiple orders should have a array of those orders pass into the dialog
  //       //there should a way to tell whether the orders are comfirm or not----probably a field in the order object name "confirmed"
  //       //the audio should keep playing when there is order not being take care of (confirm).
  //       // this.playAudio();
  //       this.futureCount = this.futureOrderArray.length;
  //       this.currentCount = this.allOrderArray.length;

  //     }



  //   });



  // }
  // public async loadModifiers() {

  //   this.modArray$ = await this.afs.collection(`public/modifiers/${this.selectedRestaurant.value.restaurantID}`).valueChanges().subscribe(val => {
  //     this.modArray = [];
  //     val.forEach((doc: any) => {
  //       this.modArray.push(doc);

  //     });

  //   });


  // }

  startNotify() {
    console.log('notify called')
    this.interval = setInterval(() => {
      this.playNotification()
    }, 5000);
  }
  stopNotify() {
    console.log('notify stop called')

    clearInterval(this.interval)

    console.log('notify stop called')

  }
  confirmOrder(selectedOrder: orderObject) {
    let orderRef = this.afs.doc(`internal/${selectedOrder.restaurantID}/current_orders/${selectedOrder.id}`);
    selectedOrder.confirmed = true;
    orderRef.update({ confirmed: true });

    this.unconfirmedOrders.splice(this.unconfirmedOrders.indexOf(selectedOrder), 1);
    console.log("this.unconfirmedOrders",this.unconfirmedOrders)
    if (this.unconfirmedOrders.length == 0) {
      this.stopNotify()
    }
    //this.loadOrderArrays();
  }


  async orderReady(selectedOrder: orderObject) {
    this.globalLoading = true;
    let orderRef = this.afs.doc(`internal/${selectedOrder.restaurantID}/current_orders/${selectedOrder.id}`);
    selectedOrder.ready = true;
    orderRef.update({ ready: true });
    const fun = this.functions.httpsCallable('sendReadyEmail');
    try {
      this.confirmation = await fun({ order: selectedOrder }).toPromise();
      this.globalLoading = false;
      this._snackBar.open('Order Move to Complete Tab🎉', 'Cool!', {
        duration: 10000,
      });
    } catch (err) {
      this.globalLoading = false;
      alert(err);
    }
  }




  // playAudio() {
  //   let path = '';
  //   switch (this.audioSelection) {
  //     case 0:
  //       path = '../assets/audio/alarm.mp3'
  //       break;

  //     case 1:
  //       path = '../assets/audio/swiftly.mp3'


  //   }

  //   var sound = new Howl({
  //     src: [path]
  //   });
  //   sound.play();
  // }

  playNotification() {
    var sound = new Howl({
      src: ['../assets/audio/swiftly.mp3']
    });
    sound.play();
  }

  updateOrderStatus(orderID, newState) {
    const orderDoc = this.afs.doc(`internal/${this.selectedRestaurant.value.restaurantID}/current_orders/${orderID}`);
    return orderDoc.update({ state: newState });
  }
  public resetCurrentBadge() {
    this.currentOrderBadge = '0';

  }
  public resetFutureBadge() {

    this.futureOrderBadge = '0';
  }

  isAdmin() {
    if (this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'admin') {
      return true;
    }
    else {
      return false;
    }
  }

  isManager() {
    if (this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'admin' || this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'manager') {
      return true;
    }
    else {
      return false;
    }
  }

}
