<div style="display: flex; flex-wrap: wrap; padding: 1rem 0">
  <!-- <div class="w-100 flex-sb">
    <span>Order# {{order.id}}.</span>
    <span>{{order.first}} {{order.last[0]}}.</span>
  </div> -->
  <!-- 
  <button class="btn100" color="primary" mat-flat-button class="" (click)='GetCustomer()'>
    GetCustomer
  </button> -->
  <div class="detailContainer">
    <div class="customerInfo">


      <div class="w-100">
        <p style="color: red;" *ngIf="order.canceled">{{'orderDetail.ORDER_CANCELED'|translate}}</p>
        <div class="w-100 dis-flex flex-sa m-t-8 p-b-10" *ngIf="order.orderType == 'Pickup'">

          <div style="width: 100px; padding-right: .5rem; margin-top: .5rem;">
            <mat-icon class="bigIcon">store</mat-icon>
            <p style="width: 100%; text-align: center;">Pickup</p>
          </div>

          <div class="w-100 txt-left fs-16 m-l-5">
            <h6 style="padding-top: 1rem; margin:0 !important;">{{order.first | titlecase}}
              {{order.last | titlecase}}.</h6>
            <h6 style="padding-top: .3rem; margin:0 !important;">{{phoneNumber}}</h6>
          </div>

        </div>


        <div class="w-100 dis-flex flex-sa  m-t-8 p-b-10" *ngIf="order.orderType == 'Delivery' ">

          <div style="width: 100px; padding-right: .5rem; margin-top: .5rem;">
            <mat-icon class="bigIcon">local_taxi</mat-icon>
            <p style="width: 100%; text-align: center;">Delivery</p>
          </div>
          <div class="w-100 txt-left fs-16 m-l-5">
            <h6 style="padding-top: 1rem; margin:0 !important;">{{order.first | titlecase}}
              {{order.last | titlecase}}.</h6>
            <h6 style="padding-top: .3rem; margin:0 !important;">{{phoneNumber}}</h6>
          </div>


        </div>

        <div *ngIf="order.orderType == 'Doordash' ">
          <div class="w-100 dis-flex flex-sa  m-t-8 p-b-10">
            <div style="width: 100px; padding-right: .5rem; margin-top: .5rem;">
              <mat-icon class="bigIcon">local_shipping</mat-icon>
              <p style="width: 100%; text-align: center;color:#e01111">Doordash</p>
            </div>
            <div class="w-100 txt-left fs-16 m-l-5">
              <h6 style="padding-top: 1rem; margin:0 !important;">{{order.first | titlecase}}
                {{order.last | titlecase}}.</h6>
              <h6 style="padding-top: .3rem; margin:0 !important;">{{phoneNumber}}</h6>
            </div>
          </div>
          <div style="border-style: outset; border-width: medium;">
            <div style="padding-left: .5rem;" class="txt-left">
              <h2 style=" font-weight: bold;"> Delivery ID : {{order.deliveryID}}</h2>
            </div>
            <div style="padding-left: .5rem;" class="txt-left">
              <h2 style=" font-weight: bold;"> Estimate Pickup Time :
                {{pickupTime}}</h2>
            </div>
          </div>

          <div class="txt-left">
            <h6 style=" font-weight: bold; "> Doordash Customer Service Number : (855)-973-1040
            </h6>
          </div>
        </div>

        <div *ngIf="order.isFutureOrder" class="futureOrder">
          <h6 style="margin: 0; font-style: normal;">{{'orderDetail.Scheduled'|translate}}: {{order.futureOrderDate |
            date:"EEEE, MMM dd"}},
            {{order.futureOrderTime }}</h6>
        </div>
        <div *ngIf="order.orderType == 'Delivery'|| order.orderType == 'Doordash'" class="w-100 txt-left  p-b-10">
          <h6 style="margin: 0; padding-bottom: .2rem; font-style: normal;">{{order.deliveryAddress}}</h6>
          <h6 style="margin: 0; padding-bottom: .2rem; font-style: normal;">Apt/Unit: {{order.aptNum}}</h6>
        </div>

        <p *ngIf="order.confirmed && this.show">✅ {{'orderDetail.Comfirmed'|translate}}</p>


        <div *ngIf="order.orderInstructions" class="instructions">
          <p style="margin: 0; font-style: normal; font-size: 12px;">{{'orderDetail.Order_Instructions'|translate}}</p>
          {{order.orderInstructions}}
        </div>
        <div *ngIf="this.restaurantDoc.askUtensil == true && order.utensil == true" class="instructions">

          Please Include Utensils
        </div>

      </div>
      <button class="btn100" *ngIf="!order.confirmed && inhouseConfig.mustConfirm" color="primary" mat-flat-button
        class="w-100 xlButton blink-bg" (click)='orderService.confirmOrder(order)'>
        <mat-icon>done</mat-icon> {{'orderDetail.Confirm'|translate}}
      </button>
      <button
        *ngIf="((order.confirmed && inhouseConfig.mustConfirm)||(!inhouseConfig.mustConfirm)) && !order.ready && order.orderType=='Pickup' && this.show"
        color="primary" mat-flat-button class="xlButton actionButton" (click)='orderService.orderReady(order)'>
        <mat-icon>done_outline</mat-icon> {{'orderDetail.Ready_for_Pickup'|translate}}
      </button>
      <button
        *ngIf="((order.confirmed && inhouseConfig.mustConfirm)||(!inhouseConfig.mustConfirm)) && !order.ready && order.orderType=='Delivery' && this.show"
        color="primary" mat-flat-button class="xlButton actionButton" (click)='orderService.orderReady(order)'>
        <mat-icon>done_outline</mat-icon> {{'orderDetail.Out_for_Delivery'|translate}}
      </button>
      <button
        *ngIf="((order.confirmed && inhouseConfig.mustConfirm)||(!inhouseConfig.mustConfirm)) && order.ready  && this.show"
        color="accent" mat-flat-button class="xlButton" (click)='sendReminder(order)'>
        <mat-icon>notifications_active</mat-icon> {{'orderDetail.Send_Reminder'|translate}}
      </button>
      <button color="primary" mat-flat-button class="xlButton" (click)='printOrder(order)'>
        <mat-icon>receipt_log</mat-icon> {{'orderDetail.Print'|translate}}
      </button>
      <button *ngIf="this.show" color="primary" mat-flat-button class="xlButton" (click)='openEditDialog(order)'>
        <mat-icon>edit</mat-icon> {{'orderDetail.Edit'|translate}}
      </button>
      <button *ngIf="this.show" color="primary" mat-flat-button class="xlButton" (click)='openAddItemDialog(order)'>
        <mat-icon>add_circle_outline</mat-icon> {{'orderDetail.Add'|translate}}
      </button>
      <button *ngIf="this.show" color="primary" mat-flat-button class="xlButton" (click)='openChangeTipDialog(order)'>
        <mat-icon>add_circle_outline</mat-icon> {{'orderDetail.Change_tip'|translate}}
      </button>
      <button *ngIf="this.show" color="primary" mat-flat-button class="xlButton" (click)='openRemoveDialog(order)'>
        <mat-icon>remove_circle_outline</mat-icon> {{'orderDetail.Remove'|translate}}
      </button>
    </div>

    <mat-card class="receipt">
      <div *ngFor="let item of order.items" class="w-100 dis-flex flex-w flex-sb">

        <p class="w-100">
          <span class="qty" style="width: 10%;">{{item.quantity}}x </span>
          <span class="orderItem" style="width: 90%;">{{item.name}}</span>
        </p>




        <!-- Start Modifiers -->
        <div class="modifiers">
          <!-- HARD CODED Modifiers -->
          <p *ngIf="item.size && item.size!='Regular'&& item.size!='Price'" class="w-100">{{item.size}}</p>
          <p *ngIf="item.couponItem" class="w-100">Coupon Item</p>
          <!-- Dynamic Modifiers -->
          <div *ngFor="let mod of modifiers" class="w-100">
            <div *ngIf="item[mod.name]">

              <div [ngSwitch]="mod.type">

                <!-- dropdown -->
                <p *ngSwitchCase="'dropdown'">{{ mod.displayName }}: {{item[mod.name]}}</p>

                <!-- multi-dropdown -->
                <div *ngSwitchCase="'multi-dropdown'">
                  <p>Add:
                    <span *ngFor="let c of item[mod.name]">
                      <span *ngIf="item[mod.name].indexOf(c)!=item[mod.name].length-1">{{c}}, </span>
                      <span *ngIf="item[mod.name].indexOf(c)==item[mod.name].length-1">{{c}}.</span>
                    </span>
                  </p>
                </div>

                <!-- increasable_dropdown -->
                <div *ngSwitchCase="'increasable_dropdown'">
                  <p>Add:
                    <span *ngFor="let c of item[mod.name]">
                      <span *ngIf="item[mod.name].indexOf(c)!=item[mod.name].length-1">{{c}}, </span>
                      <span *ngIf="item[mod.name].indexOf(c)==item[mod.name].length-1">{{c}}.</span>
                    </span>
                  </p>
                </div>

                <div *ngSwitchCase="'toggle'">
                  <p>Choice:
                    <span *ngFor="let c of item[mod.name]">
                      <span *ngIf="item[mod.name].indexOf(c)!=item[mod.name].length-1">{{c}}, </span>
                      <span *ngIf="item[mod.name].indexOf(c)==item[mod.name].length-1">{{c}}.</span>
                    </span>
                  </p>
                </div>

                <!-- multi-dropdown-limited -->
                <div *ngSwitchCase="'multi-dropdown-limited'">
                  <p>{{ mod.displayName }}: <span *ngFor="let c of item[mod.name]">
                      <span *ngIf="item[mod.name].indexOf(c)!=item[mod.name].length-1">{{c}}, </span>
                      <span *ngIf="item[mod.name].indexOf(c)==item[mod.name].length-1">{{c}}.</span>
                    </span>
                  </p>
                </div>

                <!-- boolean -->
                <div *ngSwitchCase="'boolean'">{{ mod.displayName }}</div>

                <!-- not supporting incrementor -->

                <div *ngSwitchDefault>
                  <p style="color: red;">Error: Uncaught Modifier Type</p>
                  <pre>{{mod.displayName}}: {{item[mod.name] | json}}</pre>
                </div>
              </div>

            </div>
          </div>

          <p class="instructions" *ngIf="item.instructions"><b>Note: {{item.instructions}}</b></p>
        </div>
        <p style="text-align: right;" class="itemPrice w-100">{{item.price | currency}}</p>
        <!-- End Modifiers -->



      </div>

      <div class="lineItems">
        <p>
          <span>Subtotal</span>
          <span>{{order.subTotal | currency}}</span>
        </p>
        <p *ngIf="order.discount" class="discountNum">
          <span>Discount</span>
          <span class="ml-auto">{{order.discount | currency}}</span>
        </p>

        <p>
          <span>Tax</span>
          <span class="ml-auto">{{order.tax | currency}}</span>
        </p>
        <p>
          <span>Tip</span>
          <span class="ml-auto">{{order.tipAmount | currency}}</span>
        </p>
        <p>
          <span>Fee</span>
          <span class="ml-auto">{{order.convenienceFee | currency}}</span>
        </p>

        <div class="addFees" *ngIf="order.additionalFees">
          <p *ngFor="let additionFee of order.additionalFees">
            <span>{{additionFee.displayName}}</span>
            <span class="ml-auto">{{additionFee.value | currency}}</span>
          </p>
        </div>

        <div class="addFees" *ngIf="order.orderType == 'Doordash'">
          <p>
            <span style="color: #18df61;">Doordash Delivery Fee</span>
            <span class="ml-auto" style="color: #18df61;">{{restaurantDoc.doordashFee | currency}}</span>
          </p>
        </div>


        <p>
          <span>Total</span>
          <span class="ml-auto">{{order.total | currency}}</span>
        </p>
      </div>
    </mat-card>
  </div>


  <div class="w-100 txt-left">
    <button color="accent" (click)="refund(order)" mat-flat-button
      style="margin: 5px 0 !important; justify-self: start;">
      <mat-icon>cancel</mat-icon> {{'orderDetail.Cancel_Order'|translate}}
    </button>
  </div>

</div>