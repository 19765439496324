import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from "@angular/router";
import { map, startWith, switchMap } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { ItemEditorComponent } from '../item-editor/item-editor.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-editor',
  templateUrl: './menu-editor.component.html',
  styleUrls: ['./menu-editor.component.css']
})
export class MenuEditorComponent implements OnInit, OnDestroy {
  mySub;
  restaurant$;
  restaurant;
  restaurantSub;
  groupSub;
  menuItems;
  panelOpenState = false;
  groups;
  changes = {};
  public editingMode = false;
  public pre_options = [];
  options: string[] = [];
  itemControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(public orderService: OrderArrayService,private route:ActivatedRoute, private afs: AngularFirestore,public dialog: MatDialog,) {

  }

  ngOnInit() {
    this.orderService.globalLoading = true;
    // this.orderService.selectedRestaurant.subscribe(val => {
    //   if (val) {
    //     this.loadMenu();

    //   }
    // })
    this.restaurant$ = this.route.paramMap.pipe(      
      switchMap(params=>{
        console.log('in the constructor')
        const restaurant=params.get('restaurantID');
        console.log('restaurant: ',restaurant)
        this.restaurant=restaurant;
        this.loadMenu();
        return this.afs.doc('restaurants/'+restaurant).valueChanges();
      }

      )
       
    ).subscribe();


    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadMenu() {
    this.groupSub = this.afs.doc(`public/groups/${this.restaurant}/Groups`).valueChanges().subscribe((val:any) => {
      this.groups = val.GroupNames;
    });

    this.mySub = this.afs.collection(`public/menu/${this.restaurant}`).valueChanges().subscribe(val => {
      this.menuItems = val;
      this.orderService.globalLoading = false;
    });

    

  }

  ngOnDestroy() {
    this.mySub.unsubscribe();
  }

  async toggleEditingMode() {
    if (this.editingMode == true) {
      this.orderService.globalLoading = true;

      // update db

        try {
          for (const item in this.changes) {
          await this.afs.doc(`public/menu/${this.restaurant}/${item}`).update({
            available: this.changes[item]
          });
        }
        } catch (error) {
          console.log(error);
          alert('Oops there was an error. Contact your rep if this error keeps occuring. ')
        }
        finally {
          this.editingMode = !this.editingMode;
          this.changes = {};
          this.orderService.globalLoading = false;
        }

  

    }
    else {
      this.editingMode = !this.editingMode;

    }


  }
  cancelEditing() {
    this.editingMode = !this.editingMode;
    // undo changes
    this.loadMenu();
    this.changes = {};
  }

  openEditDialog(item): void {
    const addDialogRef = this.dialog.open(ItemEditorComponent, {
      width: '100vw',
      height: '70vh',
      data: { item: item , restaurantID:this.restaurant}
    });
  }
  
  getItem(itemName) {
    for (let index = 0; index < this.pre_options.length; index++) {
      if (this.pre_options[index].name == itemName) {
        return this.pre_options[index];
      }
    }
  }

  openDialog(menuItem): void {
    const dialogNow = this.dialog.open(ItemEditorComponent, {
      autoFocus: false,
      width: '400px',
      maxWidth: '95vw',
      data: { item:menuItem, restaurantID:this.restaurant }

    });
  }

  toggled(item, $event) {
    this.changes[item.name] = $event.checked;
    console.log('changes', this.changes)
  }


  loadSearch() {
    if (this.pre_options.length == 0 && this.options.length == 0) {
      for (let i = 0; i < this.menuItems.length; i++) {
        //  console.log('this.orderForm.menuList[i];',this.orderService.menuList[i])
        const element =this.menuItems[i];
        this.pre_options.push(element);
        this.options.push(element.name);
      }
      // console.log ('this.options',this.options)
      // console.log(this.pre_options)
    }

  }
  clearSearch() {
    this.itemControl.setValue('');
  }


}
