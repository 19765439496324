import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderArrayService } from '../order-array.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Restaurant } from '../interface';

@Component({
  selector: 'app-remove-item',
  templateUrl: './remove-item.component.html',
  styleUrls: ['./remove-item.component.css']
})
export class RemoveItemComponent implements OnInit {
  removeMap = {};
  restaurantDoc: Restaurant;

  constructor(public orderService: OrderArrayService, private afs: AngularFirestore, public dialogRef: MatDialogRef<RemoveItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public fns: AngularFireFunctions) {

    this.newTotal = data.order.total;
    this.afs.doc(`restaurants/${this.data.order.restaurantID}`).get().toPromise().then((doc) => {
      this.restaurantDoc = doc.data() as Restaurant;
      console.log("this.restaurantDoc: ", this.restaurantDoc)
    });
  }

  ngOnInit() {
  }
  Arr = Array
  newTotal;
  result;

  calculateDifference(e, i, j, item) {

    let itemIndex = this.data.order.items.indexOf(item);
    let singlePrice = item.price / item.quantity;
    //const found = this.removeMap.find(element => element.key == itemIndex)
    //console.log('found: ', found, 'type: ', typeof (found))
    //if there is one of the item already selected
    if (this.removeMap[itemIndex]) {

      let count = this.removeMap[itemIndex]

      if (e.checked) {
        this.removeMap[itemIndex] = count + 1
        this.newTotal -= singlePrice;
      }
      else {
        this.removeMap[itemIndex] = count - 1
        this.newTotal += singlePrice;
      }
    }
    //if there is none of the item already selected
    else {


      this.removeMap[itemIndex] = 1;
      this.newTotal -= singlePrice;
    }
    // if (e.checked) {
    //   this.removeMap.push({ i: j });
    //   this.newTotal -= item.price;


    // } else {
    //   let iOf = this.removeMap.indexOf(i);
    //   if (iOf != -1) {
    //     this.removeMap.splice(iOf, 1);
    //   }
    //   this.newTotal += item.price;

    // }
  }

  async remove() {

    let order_ID = this.data.order.id.toString();

    //get current doc
    let itemDoc: AngularFirestoreDocument<any> = await this.afs.doc(`internal/${this.data.order.restaurantID}/current_orders/${order_ID}`);
    //console.log('order id is ', order_ID);

    //getCurrentArray
    let oldItems = this.data.order.items;
    let itemIndex = [];
    let newItems = [];

    for (const key in this.removeMap) {
      if (oldItems[key].quantity == this.removeMap[key]) {
        itemIndex.push(parseInt(key));
      } else {
        let Qty = oldItems[key].quantity;
        let unitPrice = oldItems[key].price / Qty;
        oldItems[key].quantity = oldItems[key].quantity - this.removeMap[key];
        oldItems[key].price = unitPrice * oldItems[key].quantity
      }

    }



    //push items to new array that are not in old array
    for (let i = 0; i < oldItems.length; i++) {
      if (itemIndex.indexOf(i) == -1) {
        newItems.push(oldItems[i]);
      }

    }


    //set doc items
    await itemDoc.set({ items: newItems }, { merge: true });


    //   Calculate subtotal

    let newSubtotal = 0;

    for (let i = 0; i < newItems.length; i++) {
      newSubtotal += newItems[i].price;
    }



    // Update firestore totals

    let charge_ID = this.data.order.charge_ID;
    //delivery fee
    //convent


    let newTax = (newSubtotal) * (this.restaurantDoc.taxRate / 100);
    let newConvenienceFee = this.data.order.convenienceFee;
    // if (this.orderService.selectedRestaurant.value.addConvenienceFee.active == true) {
    //   switch (this.orderService.selectedRestaurant.value.addConvenienceFee.type) {
    //     case 'number':
    //       newConvenienceFee = this.orderService.selectedRestaurant.value.addConvenienceFee.fee;
    //       break;
    //     case 'percent':
    //       let tempPercent = newSubtotal + newTax;
    //       newConvenienceFee = tempPercent * (this.orderService.selectedRestaurant.value.addConvenienceFee.fee / 100);
    //       break;
    //     default:
    //       break;
    //   }
    // }

    let oldTip = this.data.order.tipAmount;
    let newTotal = oldTip + newSubtotal + newConvenienceFee + newTax;
    if (this.data.order.orderType == 'Doordash') {
      console.log("restaurant:", this.data.restaurant)
      newTotal += this.data.restaurant.doordashFee
    }

    let trimmedTotal = newTotal.toFixed(2);
    newTotal = parseFloat(trimmedTotal);
    // let currentIndex = this.orderService.allOrderArray.indexOf(this.orderService.selectedOrder)
    //update totals
    await itemDoc.update({
      "subTotal": newSubtotal,
      "convenienceFee": newConvenienceFee,
      "tax": newTax,
      "total": newTotal
    }).then((val) => {
      // this.orderService.selectedOrder = this.orderService.allOrderArray[currentIndex]
      alert('Order Was Updated!');
    });


  }

}
