import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
//import { PriceService } from '../../services/price.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
//import { FirestoreService } from '../../services/firestore.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { OrderArrayService } from '../order-array.service';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ConfirmComponent implements OnInit {

  public addItemForm: FormGroup;
  loading = false;
  confirmed = false;
  subTotal;
  tax;
  tipAmount;
  convenienceFee;
  total;
  confirmation;
  neworder;
  inputNewCard = false;
  constructor(public orderService: OrderArrayService, private dialogRef: MatDialogRef<ConfirmComponent>, private fb: FormBuilder, private cd: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any, private functions: AngularFireFunctions, private router: Router, private DialogSer: MatDialog) {
    this.addItemForm = this.fb.group({
      name: ['PRICE ADJUST',],
      price: ['', [Validators.required]],
      quantity: 1,
      instructions: ''
    });
  }

  ngOnInit() {
    this.subTotal = this.data.order.subTotal;
    this.convenienceFee = this.data.order.convenienceFee
    this.tax = this.data.order.tax;
    this.tipAmount = this.data.order.tipAmount;
    this.total = this.data.order.total;
  }
  confirm() {
    // console.log("this.data.restaurant",this.data.restaurant)
    this.confirmed = true;
    this.subTotal = this.data.order.subTotal + this.addPrice;
    this.convenienceFee = this.data.order.convenienceFee
    this.tax = Math.round((this.subTotal) * this.data.restaurant.taxRate) / 100;
    this.tipAmount = this.data.order.tipAmount;
    this.total = this.subTotal + this.convenienceFee + this.tax + this.tipAmount + this.data.order.discount;
    if (this.data.order.orderType == 'Doordash') {
      console.log("restaurant:", this.data.restaurant)
      this.total += this.data.restaurant.doordashFee
    }
    this.total = (Math.round(this.total * 100)) / 100;
    this.neworder = Object.assign(this.data.order);
    // this.neworder.subTotal=this.subTotal;
    // this.neworder.tax=this.tax;
    // this.neworder.tipAmountAmount=this.tipAmount;
    // this.neworder.total=this.total;
    // this.neworder.ConvenienceFee=this.convenienceFee;
    // this.neworder.items=this.neworder.items.concat(this.priceService.currentCartItems.value)
  }
  async ChargeOrigin() {
    this.loading = true;
    const fun = this.functions.httpsCallable('global_addToOrder_v2');
    try {
      this.confirmation = await fun({
        order: this.data.order,
        newItem: this.addItemForm.value,
        prevChargeID: this.data.order.chargeID,
        newSubtotal: this.subTotal,
        newTax: this.tax,
        newConvenienceFee: [this.convenienceFee],
        amount: this.total,
      }).toPromise();
      this.cd.detectChanges();
      this.DialogSer.closeAll();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      alert(err);

    }
    if (this.confirmation) {

    }
  }
  newCard() {
    this.inputNewCard = true;
  }
  clearName() {
    this.addItemForm.get('name').setValue('');

  }
  close() {
    this.dialogRef.close();
  }
  clearPrice() {
    this.addItemForm.get('price').setValue(0);

  }
  clearInstructions() {
    this.addItemForm.get('instructions').setValue('');

  }

  get addPrice() {
    return parseFloat(this.addItemForm.get('price').value);
  }

  originalPaymentAdd() {
  }
}
