<div style="margin: 15px;">
  <mat-card>
    <mat-card-content>

      <h2 class="m-b-10">{{'admin.Estimated_Order_Completion_Time'|translate}}</h2>
      <p>Delivery Orders</p>
      <mat-form-field appearance="fill" style="margin-top: 1rem;" *ngIf="checked == true">
        <mat-label>Delivery Orders</mat-label>

        <mat-select [(ngModel)]="deliveryETA" (selectionChange)="deliveryTimeChange($event)">
          <mat-option *ngFor="let time of deliveryTimes" [value]="time">{{time}}</mat-option>

        </mat-select>
      </mat-form-field>


      <p class="m-t-10">Pickup Orders</p>
      <mat-form-field appearance="fill" style="margin-top: 1rem;" *ngIf="checked == true">
        <mat-label>Pickup Orders</mat-label>

        <mat-select [(ngModel)]="pickupETA" (selectionChange)="pickupTimeChange($event)">
          <mat-option *ngFor="let time of pickupTimes" [value]="time">{{time}}</mat-option>

        </mat-select>
      </mat-form-field>

    </mat-card-content>

  </mat-card>

  <mat-card>
    <mat-card-content>
      <h2 class="example-h2 m-b-10">{{'admin.Delivery_switch'|translate}}</h2>
      <section class="example-section">
        <mat-slide-toggle (change)="deliveryChange()" class="example-margin  m-b-20" color="accent"
          [(ngModel)]="takesDelivery">
          <span *ngIf="takesDelivery == true">{{'admin.Accepting_Delivery'|translate}} </span><span
            *ngIf="takesDelivery == false">{{'admin.Not_Accepting_Delivery'|translate}}!</span>
        </mat-slide-toggle>
        <hr>
        <div *ngIf='deliveryAreaToggle'>
          <h2 class="m-t-10 m-b-10">{{'admin.Edit_DeliveryArea'|translate}}</h2>
          <div style="width: auto;">
            <div class="m-b-10">
              <google-map width="100%" [options]="mapOptions">
                <map-marker [position]="center" [options]="markerOptions"></map-marker>
              </google-map>
            </div>
            <div>
              <button mat-flat-button color="accent" class="mr-1" (disabled)="polygon.getEditable()"
                (click)="enableAreaEdit()">{{'admin.Edit'|translate}}</button>
              <button mat-flat-button color="primary" class="ml-1"
                (click)="confirmAreaEdit()">{{'admin.Update'|translate}}</button>
            </div>
          </div>
        </div>
      </section>
    </mat-card-content>

  </mat-card>



  <mat-card class="result">
    <mat-card-content>
      <h2 class="example-h2 m-b-10">{{'admin.Ordering_switch'|translate}}</h2>
      <section class="example-section">
        <mat-slide-toggle (change)="activationChange()" class="example-margin  m-b-20" color="accent"
          [(ngModel)]="checked">
          <span *ngIf="checked == true">{{'admin.Accepting_Orders'|translate}} </span><span
            *ngIf="checked == false">{{'admin.Not_Accepting_Orders'|translate}}!</span>
        </mat-slide-toggle>
        <hr>
        <mat-divider></mat-divider>
        <div style="margin-top: 2rem; margin-bottom: 2rem;">
          <button mat-flat-button (click)="openTimeEdit()" color="accent">Edit Store Time</button>
        </div>
        <mat-divider></mat-divider>
        <div style="margin-top: 2rem; margin-bottom: 2rem;">
          <button mat-flat-button (click)="openNoteEdit()" color="accent">Edit Store Note</button>
        </div>
        <mat-divider></mat-divider>
        <div style="margin-top: 2rem; margin-bottom: 2rem;">
          <button mat-flat-button (click)="openPrintNotic()" color="accent">Print and Notification</button>
        </div>

        <h2 class="m-t-10 m-b-10">{{'admin.Closed_Message'|translate}}</h2>
        <p>{{'admin.message_displayed'|translate}}</p>
        <div class="space-evenly">
          <mat-form-field>
            <mat-label>{{'admin.Closed_Message'|translate}}</mat-label>
            <textarea [(ngModel)]="closedMessageValue" [disabled]="closedMessageDisabled" matInput
              placeholder="We are closed for Christmas. 🎄"></textarea>
          </mat-form-field>
        </div>
        <div class="">
          <button mat-flat-button color="accent" class="mr-1"
            (click)="closedMessageDisabled = !closedMessageDisabled">{{'admin.Edit'|translate}}</button>
          <button mat-flat-button color="primary" class="ml-1"
            *ngIf="closedMessageValue != orderService.selectedRestaurant.value.closedMessage"
            (click)="updateClosedMessage()">{{'admin.Update'|translate}}</button>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <!-- <mat-card>
    <h3>Manually Archive Orders</h3>
    <button mat-flat-button matSuffix aria-label="Clear" (click)="archiveOrders()" color="primary">Archive
      Orders</button>
  </mat-card> -->
</div>