import { Component, OnInit, Inject } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { ReservationService } from '../reservation.service';
import { FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment-timezone';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'app-previous-reservation',
  templateUrl: './previous-reservation.component.html',
  styleUrls: ['./previous-reservation.component.css']
})
export class PreviousReservationComponent implements OnInit {
  public ReservationLookupType='date';
  dateLookupArray= [];
  selectedRestaurant;
 
  
  constructor(private afs: AngularFirestore, public resService: ReservationService,public orderService: OrderArrayService,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedRestaurant=data.selectedRestaurant;
   }
   checkedDate;
   previousResArray$;
  ngOnInit(): void {
    this.checkedDate = new FormControl(new Date());
  }
  async gerDateOrders() {

    this.orderService.globalLoading = true;
    this.dateLookupArray = [];

    try {

      console.log(this.checkedDate.value);

      let previousDate=new Date(this.checkedDate.value)  
      let currentDate=new Date(this.checkedDate.value)  
      previousDate.setDate(previousDate.getDate()-1)
      let startTime =previousDate.setHours(23, 59, 0, 0);
      let endTime = currentDate.setHours(23, 59, 0, 0);



      this.previousResArray$= await this.afs.collection(`reservations/${this.selectedRestaurant.value.restaurantID}/previous`,
      ref => ref.where('date', '>', new Date(startTime)).where('date', '<', new Date(endTime))).valueChanges().subscribe((snapshots) => {

          snapshots.forEach((doc: any) => {
            this.dateLookupArray.push(doc);
          });
          this.dateLookupArray = this.dateLookupArray.sort(this.compare);
          this.orderService.globalLoading = false;
        });
    } catch (error) {

      alert('Database Error' + error);
      this.orderService.globalLoading = false;
    }

  }
  compare(a, b) {    
    let aTime=moment(a.time, ["h:mm A"]).format("HH:mm");
    let bTime=moment(b.time, ["h:mm A"]).format("HH:mm");
    let aHour=aTime.split(':')[0];
    let bHour=bTime.split(':')[0];
    let aMin=aTime.split(':')[1];
    let bMin=bTime.split(':')[1];
    if (aHour < bHour) {
      return -1;
    }else if (aHour == bHour && aMin < bMin) {
      return -1;
    }
    return 1;
  }

}
