import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface Item { name: string; }

@Component({
  selector: 'app-item-editor',
  templateUrl: './item-editor.component.html',
  styleUrls: ['./item-editor.component.css']
})
export class ItemEditorComponent implements OnInit {

  item;

  BasePrices = [];
  description = '';
  private itemDoc: AngularFirestoreDocument<Item>;

  constructor( private afs: AngularFirestore,private dialogRef: MatDialogRef<ItemEditorComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    
    
    this.item = data.item;
    console.log('this.item: ', this.item);
    this.BasePrices=[...this.item.basePrices];
    console.log('this.BasePrices: ', this.BasePrices);
    this.itemDoc = afs.doc<Item>(`public/menu/${this.data.restaurantID}/${this.item.name}`);
    
  }


  toggled(item, $event) {
    
    console.log('changes', this.item)
  }
  close(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }
  confirm(){
    this.itemDoc.update(this.item);
  }

}
