<div>
  <p class="w-100 mb-0">Pickup {{'edit.or'|translate}} Delivery?
  <mat-form-field class="ml-2" >
    <mat-label>{{'edit.Change_Order_Type'|translate}}</mat-label>
    <mat-select [(value)]="orderType">
      <mat-option *ngFor="let orderType of orderTypes" [value]="orderType">
        {{orderType}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</p>
  <mat-form-field class="w-75">
    <input [(ngModel)]="deliveryAddress" matInput placeholder="{{'edit.verify_address'|translate}}...." google-place>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="verifyAddress()" class="col-sm-12 col-md-2"> {{'edit.Verify'|translate}}</button>
  <mat-spinner *ngIf="loading" class="loading mx-auto"></mat-spinner>
  <h6 class="col-12 text-center mx-auto" *ngIf="message != ''">{{ message }}</h6>

  <mat-form-field class="w-50 my-1" style="display: inline-block; ">
    <input [(ngModel)]="aptNum" matInput placeholder="Apt 101">
  </mat-form-field>

  <div *ngIf="data.selectedOrder.isFutureOrder">
    <input [matDatepicker]="picker" placeholder="Change the Date" class="w-75" readonly (click)="picker.open()"
      [(ngModel)]="date">
    <mat-datepicker-toggle matSuffix [for]="picker" class="clock ml-2">
    </mat-datepicker-toggle>
    <mat-datepicker touchUi #picker></mat-datepicker>
    <mat-form-field>
      <mat-label>Change the Time</mat-label>
      <mat-select [(value)]="time">
        <mat-option *ngFor="let time of times" [value]="time">
          {{time}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button mat-raised-button matStepperNext color="accent" class="w-100 my-3 mx-auto block" (click)="updateTime()"
    mat-dialog-close>{{'edit.Confirm'|translate}}</button>

</div>
