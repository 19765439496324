import { ChangeDetectorRef, Inject, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderArrayService } from '../order-array.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Component({
  selector: 'app-change-tip-dialog',
  templateUrl: './change-tip-dialog.component.html',
  styleUrls: ['./change-tip-dialog.component.css']
})
export class ChangeTipDialogComponent implements OnInit {

  public tipForm: FormGroup;
  loading = false;
  confirmed = false;
  subTotal;
  tax;
  tipAmount;
  convenienceFee;
  total;
  confirmation;
  neworder;
  inputNewCard = false;
  constructor(
    public orderService: OrderArrayService,
    private dialogRef: MatDialogRef<ChangeTipDialogComponent>,
    private fb: FormBuilder, private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private functions: AngularFireFunctions,
    private router: Router, private DialogSer: MatDialog,
    private afs: AngularFirestore,
  ) {
    this.tipForm = this.fb.group({
      amount: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.subTotal = this.data.order.subTotal;
    this.convenienceFee = this.data.order.convenienceFee
    this.tax = this.data.order.tax;
    this.tipAmount = this.data.order.tipAmount;
    this.total = this.data.order.total;
  }
  confirm() {
    // console.log("this.data.restaurant",this.data.restaurant)
    this.confirmed = true;
    console.log('Tip Value', this.tipForm.get('amount').value)

    if (this.tipAmount > this.tipForm.get('amount').value) {


      this.tipAmount = this.tipForm.get('amount').value
      this.total = this.subTotal + this.convenienceFee + this.tax + this.tipAmount + this.data.order.discount;
      if (this.data.order.additionalFees) {
        for (let additionalFee of this.data.order.additionalFees) {
          this.total += additionalFee.value
        }
      }
      if (this.data.order.orderType == 'Doordash') {
        console.log("restaurant:", this.data.restaurant)
        this.total += this.data.restaurant.doordashFee
      }
      this.total = (Math.round(this.total * 100)) / 100;
      //just change the data in the database
      this.decreaseTip()

    } else if (this.tipAmount < this.tipForm.get('amount').value) {
      this.tipAmount = this.tipForm.get('amount').value
      this.total = this.subTotal + this.convenienceFee + this.tax + this.tipAmount + this.data.order.discount;
      if (this.data.order.additionalFees) {
        for (let additionalFee of this.data.order.additionalFees) {
          this.total += additionalFee.value
        }
      }
      if (this.data.order.orderType == 'Doordash') {
        console.log("restaurant:", this.data.restaurant)
        this.total += this.data.restaurant.doordashFee
      }
      this.total = (Math.round(this.total * 100)) / 100;
      //call the cloud function to cancel the old charge and charge the new amount
      this.ChargeOrigin()
    }




    // this.tipAmount = this.tipForm.get('amount').value
    // this.total = this.subTotal + this.convenienceFee + this.tax + this.tipAmount + this.data.order.discount;

    // if (this.data.order.orderType == 'Doordash') {
    //   console.log("restaurant:", this.data.restaurant)
    //   this.total += this.data.restaurant.doordashFee
    // }
    // this.total = (Math.round(this.total * 100)) / 100;
    // this.neworder = Object.assign(this.data.order);
    // this.neworder.subTotal=this.subTotal;
    // this.neworder.tax=this.tax;
    // this.neworder.tipAmountAmount=this.tipAmount;
    // this.neworder.total=this.total;
    // this.neworder.ConvenienceFee=this.convenienceFee;
    // this.neworder.items=this.neworder.items.concat(this.priceService.currentCartItems.value)
  }
  async ChargeOrigin() {
    this.loading = true;
    const fun = this.functions.httpsCallable('global_addTip_v2');
    try {
      this.confirmation = await fun({
        order: this.data.order,
        newTip: this.tipForm.get('amount').value,
        prevChargeID: this.data.order.chargeID,
        newSubtotal: this.subTotal,
        newTax: this.tax,
        newConvenienceFee: [this.convenienceFee],
        amount: this.total,
      }).toPromise();
      this.cd.detectChanges();
      this.DialogSer.closeAll();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      alert(err);

    }
    if (this.confirmation) {

    }
  }
  newCard() {
    this.inputNewCard = true;
  }

  close() {
    this.dialogRef.close();
  }
  clearTip() {
    this.tipForm.get('amount').setValue(null);

  }

  async decreaseTip() {
    this.loading = true;
    let order_ID = this.data.order.id.toString();

    //get current doc
    let itemDoc: AngularFirestoreDocument<any> = await this.afs.doc(`internal/${this.data.order.restaurantID}/current_orders/${order_ID}`);
    try {
      await itemDoc.update({
        "tipAmount": this.tipAmount,
        "total": this.total
      }).then((val) => {
        // this.orderService.selectedOrder = this.orderService.allOrderArray[currentIndex]
        alert('Tip Was Updated!');
      });
      this.loading = false;

    } catch (error) {
      this.loading = false;
      alert(error);
    }

  }


  get addPrice() {
    return parseFloat(this.tipForm.get('amount').value);
  }

  originalPaymentAdd() {
  }



}
