import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { OrderTableItem } from './order-table-datasource';
import { AngularFirestore } from '@angular/fire/firestore';
import { animate, state, style, transition, trigger, group } from '@angular/animations';
import { orderObject, inhouseConfig } from '../interface';
import { OrderArrayService } from '../order-array.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(150, style({ height: 0 })),
          animate('200ms ease-in-out', style({'opacity': '0'}))
        ])

      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(150, style({ height: '*' })),
          animate('200ms ease-in-out', style({ 'opacity': '1' }))
        ])

      ])
    ])
  ],
})

export class OrderTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OrderTableItem>;
  @Input() inhouseConfig: inhouseConfig;
  @Input() restaurant; 
  @Input() restaurantDoc;
  @Input() modifiers;
  @Input() orders;

  dataSource: MatTableDataSource<any>;
  orderNumber;
  searchOption='Phone Number';
  searching=false;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'first', 'orderType', 'orderTime', 'placedAt'];
  isTableExpanded = false;
  phoneControl = new FormControl();
  numberControl = new FormControl();
  nameControl = new FormControl();
  constructor(private afs: AngularFirestore,  public orderService: OrderArrayService) {
  

  }


  ngOnInit() {

   

  }

  ngAfterViewInit() {

    this.dataSource.sort = this.sort;
    this.afs.collection<any>(`internal/${this.restaurant}/current_orders`).valueChanges().subscribe(data => {
      data.forEach(d => d.isExpanded = false)

      // this.dataSource.sort = this.sort;
    })
  }

  ngOnChanges() {
    console.log('change detected', this.orders.length)
    this.dataSource = new MatTableDataSource(this.orders);
   

  }
  searchSwitch(){
    this.searching = !this.searching;
    if (this.searching==false) {
      console.log('cleaning value')
      this.phoneControl.setValue('');  
      this.numberControl.setValue(''); 
      this.nameControl.setValue(''); 
      this.dataSource.filter = '';      
    } 
  }

  applyIdFilter() {
    this.dataSource.filterPredicate = (data: orderObject, filter: string) => {
      return data.id.toLocaleLowerCase().includes(filter);
    }
    const filterValue = this.numberControl.value;
    this.dataSource.filter = filterValue;
   }

   applyPhoneFilter() {
    this.dataSource.filterPredicate = (data: orderObject, filter: string) => {
      return data.phoneNum.toLocaleLowerCase().includes(filter);
    }
    const filterValue = this.phoneControl.value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
   }

   applyNameFilter() {
    this.dataSource.filterPredicate = (data: orderObject, filter: string) => {
      return data.first.toLocaleLowerCase().includes(filter);
    }
    const filterValue = this.nameControl.value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
   }
  expended(row) {
    if (this.orderService.expendedOrders.includes(row.id) ) {
      this. orderService.expendedOrders.splice(this.orderService.expendedOrders.indexOf(row.id),1)
      row.isExpanded=false;
    } else {
      this. orderService.expendedOrders.push(row.id);
      row.isExpanded=true;
    }
  }
  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.dataSource.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
      if(this.isTableExpanded==true){
        this. orderService.expendedOrders.push(row.id);
      }
      else{
        this. orderService.expendedOrders=[]
      }
    })
  }
}
