export const environment = {
  production: false,
  fireConfig : {

    //dev
    // apiKey: "AIzaSyCg1lwg3lIVR_5yH3hVNy5N17OdEbWvV4s",
    // authDomain: "inhouse-dev-ddaad.firebaseapp.com",
    // databaseURL: "https://inhouse-dev-ddaad.firebaseio.com",
    // projectId: "inhouse-dev-ddaad",
    // storageBucket: "inhouse-dev-ddaad.appspot.com",
    // messagingSenderId: "140381093941",
    // appId: "1:140381093941:web:807a572f75712b9d2e717c",
    // measurementId: "G-QT3DRZKY8M"

    // //prod
    apiKey: "AIzaSyBm-VGrtFCEa4YP6fn3I3bq6We9C1CEJaQ",
    authDomain: "in-house-prod.firebaseapp.com",
    databaseURL: "https://in-house-prod.firebaseio.com",
    projectId: "in-house-prod",
    storageBucket: "in-house-prod.appspot.com",
    messagingSenderId: "29346191873",
    appId: "1:29346191873:web:9b4b134bf54b3769487e00",
    measurementId: "G-TE3YXPNYSD"
  }
};
