import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { Restaurant } from '../interface';
import { OrderArrayService } from '../order-array.service';
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-checkins',
  templateUrl: './checkins.component.html',
  styleUrls: ['./checkins.component.css']
})
export class CheckinsComponent implements OnInit {

  customersWaitingCollection;
  customersWaiting;
  restaurant$;
  restaurant;
  waitTime;
  checked = false;
  restaurantDoc;
  selectedRestaurant
  waitingCount: Observable<Restaurant>;
  waitimgTimes = [
    '0 mins',
    '10 mins',
    '20 mins',
    '30 mins',
    '40 mins',
    '50 mins',
    '60 mins',
    '70 mins',
    '80 mins',
    '90 mins',
    '90+ mins'

  ];

  constructor(private route: ActivatedRoute, public afs: AngularFirestore, public orderService: OrderArrayService, private router: Router) {

    this.selectedRestaurant = this.router.getCurrentNavigation().extras.state.selectedRestaurant;
    this.restaurant$ = this.route.paramMap.pipe(
      switchMap(params => {
        const restaurant = params.get('restaurantID');
        this.restaurant=restaurant;
        this.loadCheckInData();
        return this.afs.doc('restaurants/' + restaurant).valueChanges();
      }

      )

    ).subscribe();

    // this.restaurant$.subscribe(val => {
    //   if (val) {
    //     console.log('val', val)
    //     this.checked = val.acceptsCheckins;
    //     this.waitTime = val.waitTime

    //     let start: any = moment().tz('America/Denver');
    //     let end: any = moment().tz('America/Denver');
    //     let startDate = new Date(start);
    //     let endDate = new Date(end);

    //     startDate.setHours(0, 0, 0, 0);
    //     endDate.setHours(23, 59, 59, 9999);
    //     console.log('this.orderService.selectedRestaurant.value:', this.orderService.selectedRestaurant.value)
    //     this.customersWaitingCollection = this.afs.collection<any>(`checkins/${this.orderService.selectedRestaurant.value.restaurantID}/checkins`, ref => ref.where('created', '>=', startDate).where('created', '<=', endDate).orderBy('created', 'asc'));
    //     this.customersWaiting = this.customersWaitingCollection.valueChanges({ idField: 'id' });
    //     console.log("this.customersWaiting:", this.customersWaiting)
    //     this.restaurantDoc = this.afs.doc<Restaurant>(`restaurants/${this.orderService.selectedRestaurant.value.restaurantID}`);
    //     this.waitingCount = this.restaurantDoc.valueChanges();
    //   }
    // });




  }

  ngOnInit(): void {


  }
  loadCheckInData() {

    let docRef = this.afs.doc('restaurants/' +this.restaurant).valueChanges().subscribe((val: any) => {
      if (val) {
        console.log('val', val)
        this.checked = val.acceptsCheckins;
        this.waitTime = val.waitTime

        let start: any = moment().tz('America/Denver');
        let end: any = moment().tz('America/Denver');
        let startDate = new Date(start);
        let endDate = new Date(end);

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 9999);
        console.log('this.orderService.selectedRestaurant.value:', this.orderService.selectedRestaurant.value)
        this.customersWaitingCollection = this.afs.collection<any>(`checkins/${this.restaurant}/checkins`, ref => ref.where('created', '>=', startDate).where('created', '<=', endDate).orderBy('created', 'asc'));
        this.customersWaiting = this.customersWaitingCollection.valueChanges({ idField: 'id' });
        console.log("this.customersWaiting:", this.customersWaiting)
        this.restaurantDoc = this.afs.doc<Restaurant>(`restaurants/${this.restaurant}`);
        this.waitingCount = this.restaurantDoc.valueChanges();
      }
    });
  }


  checkinActivationChange(e) {
    this.afs.doc(`restaurants/${this.restaurant}`).update({ acceptsCheckins: e.checked })
  }

  makeTableReady(checkin) {
    this.afs.doc(`checkins/${this.restaurant}/checkins/${checkin.id}`).update({ tableReady: true })
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  waitTimeChanged(e) {
    this.afs.doc(`restaurants/${this.restaurant}`).update({ waitTime: e.value });
  }

}
