<div class="card p-30 m-l-20 m-r-20 m-b-20">
    <mat-card>
        <h1 class="text-center">{{'checkIn.Check_In_List'|translate}}</h1>
        <p style="margin-bottom: 1rem;">{{'checkIn.intro'|translate}} </p>
        <mat-slide-toggle (change)="checkinActivationChange($event)" color="accent" [(ngModel)]="checked">
            <span *ngIf="checked == true">{{'checkIn.Activated'|translate}}!</span><span *ngIf="checked == false">{{'checkIn.De-Activated'|translate}}!</span>
        </mat-slide-toggle>
        <mat-form-field appearance="fill" style="margin-top: 1rem;" *ngIf="checked == true">
            <mat-label>{{'checkIn.Current_Wait_Time'|translate}}</mat-label>

            <mat-select [(ngModel)]="waitTime" (selectionChange)="waitTimeChanged($event)">
                <mat-option *ngFor="let mins of waitimgTimes" [value]="mins">{{mins}}</mat-option>

            </mat-select>
        </mat-form-field>

    </mat-card>

    <mat-card *ngIf="checked == true">
        <div *ngIf="(waitingCount | async) as w">
            <h1 style="margin:1rem; text-align: center;">{{w.dineInQueueLength}} {{'checkIn.Guests_Waiting'|translate}}</h1>
        </div>

        <div *ngFor="let c of customersWaiting | async">
            <mat-card [ngClass]="!c.tableReady? ['blink-light']: ['']"
                style="display: flex; justify-content: space-between; align-items: center; border: 1px solid #ccc !important; flex-wrap: wrap;">

                <p class="w-100"><b>{{'checkIn.Name'|translate}}: </b>{{c.name}} <b style="margin-left: 1rem;">{{'checkIn.Phone'|translate}}:</b>
                    {{formatPhoneNumber(c.phone)}}
                </p>
                <p><b>{{'checkIn.Number_Of_Guests'|translate}}:</b> {{c.guestCount}} <b style="margin-left: 1rem;">{{'checkIn.Check_In_Time'|translate}}:</b> {{c.created.seconds*1000 | date:'shortTime'}}</p>
                <button (click)="makeTableReady(c)" color="accent" mat-raised-button
                    *ngIf="!c.tableReady">{{'checkIn.Ready'|translate}}</button>
            </mat-card>
        </div>

    </mat-card>
</div>