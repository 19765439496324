import { Component, OnInit } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  private usersCollection;
  users: Observable<any>;
  authorizeEmail = '';

  constructor(public orderService: OrderArrayService, public afs: AngularFirestore) { 
  }

  ngOnInit(): void {

  }

  async authorizeUser() {

  }

  async removeUser(Uemail) {

  }

}
