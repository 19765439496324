import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { OrderArrayService } from '../order-array.service';
import { ReservationService } from '../reservation.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Restaurant } from '../interface';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.css']
})
export class AppShellComponent implements OnInit {
  siteLanguage: string = 'English';
  selectedRestaurant$;
  user$: Observable<any>;
  public user;
  selectedRestaurant: BehaviorSubject<Restaurant> = new BehaviorSubject(<Restaurant>{});
  isExpanded = true;
  defaultRestaurantId: string;
  menu;
  inhouseConfig;
  showSearch = false;
  RestaurantSwithControl = new FormControl();
  filteredRestaurant: Observable<Restaurant[]>;
  audioSelection;
  reservations = [];
  todaysReservations = [];
  prevReservations = [];
  newResCount = 0;
  checkInCount: number;
  restaurantPricing;
  viewReady = false;
  restaurantArray = [];
  element: HTMLElement;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 800px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  isMedium$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 800px)').pipe(
    map(result => result.matches),
    shareReplay()
  );

  public localRestaurant;

  constructor(private translate: TranslateService, private breakpointObserver: BreakpointObserver, private afs: AngularFirestore, private router: Router, public afAuth: AngularFireAuth, public orderService: OrderArrayService, public resService: ReservationService) {

    this.afAuth.user.subscribe(user => {
      if (user) {
        // verify user
        console.log('afauth trigger!')
        this.afs.doc('inhouse_users/' + user.email).valueChanges().subscribe((val: any) => {
          if (val.restaurants) {
            console.log('it is a user!')

            this.user = val;
            for (let innerMap in this.user.restaurants) {
              // if (this.user.restaurants[innerMap]['default'] == true) {

              //   this.defaultRestaurantId = this.user.restaurants[innerMap]['restaurantId'];
              //   this.switchRestaurant(this.defaultRestaurantId);

              // }
              this.restaurantArray = [];
              this.afs.doc('restaurants/' + innerMap).get().toPromise().then(doc => {
                let docData = doc.data();
                this.restaurantArray.push(docData);

                if (this.user.restaurants[innerMap]['default'] == true) {

                  this.defaultRestaurantId = this.user.restaurants[innerMap]['restaurantId'];
                  this.switchRestaurant(this.defaultRestaurantId);
                }



              }).finally(() => { 
                this.restaurantArray.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);                
              }
              );

            }
          } else {
            // this.user = null;
            alert('Your account is not connected with any restaurants. Contact your rep for support. ');
          }
        },
          (err) => {
            if (err) {
              alert('Your account is not connected with any restaurants. Contact your rep for support. ');
              console.log('Error in auth.subscribe : ');
              console.log(err);
              this.afAuth.auth.signOut()
            } else {
              console.log('No Error detected in auth.subscribe');
            }
          });

      }
      else {

        this.user = null;
        console.log("logged out!")
      }
    });
    this.translate.use('en');
  }

  ngOnInit() {
    console.log('checkin Counts', this.selectedRestaurant.value.dineInQueueLength)
    this.filteredRestaurant = this.RestaurantSwithControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    // this.selectedRestaurant.subscribe(val=>{
    //   this.checkInCount=val.dineInQueueLength
    // })
    // this.orderService.selectedRestaurant.subscribe(val => {
    //   if (val) {
    //     this.localRestaurant = val;
    //     this.restaurantDoc = this.afs.doc<Restaurant>(`restaurants/${this.localRestaurant.restaurantID}`);
    //     this.checkInCount= this.restaurantDoc.valueChanges();
    //     console.log(this.checkInCount)

    //   }
    // });
  }

  public signOut() {
    if (confirm('Really sign out?')) {
      this.afAuth.auth.signOut();

    }
  }
  public async switchRestaurant(rId: string) {
    console.log('got into the restaurants')
    await this.selectedRestaurant.next(this.restaurantArray.find(r => r.restaurantID == rId));
    // console.log('after pushed value into selectedRestaurant')
    this.restaurantPricing = await (await this.afs.doc(`restaurants_secret/${rId}`).get().toPromise()).data();
    console.log(rId)
    this.router.navigate(['', rId])
    this.viewReady = true;
    // console.log("selected restuaurant: ", this.selectedRestaurant.value);
    this.checkInCount = this.selectedRestaurant.value.dineInQueueLength;



    this.afs.collection(`reservations/${rId}/upcoming`, ref => ref.orderBy('created', 'desc')).valueChanges({ idField: 'id' }).subscribe(val => {
      this.reservations = val;
      this.newResCount = this.reservations.filter(r => r.confirmed == false).length;
    });






  }

  searchSwitch(){
    this.showSearch = !this.showSearch;
  }

  switchLanguage(language: string) {
    if (language == 'en') {
      this.siteLanguage = 'English'
    } else {
      this.siteLanguage = '中文'
    }

    try {
      this.translate.use(language);
    } catch (error) {
      console.log('error: ', error)
    }


  }
  switchRoute(r) {
    this.router.navigate(['', r.restaurantID])

  }
  private _filter(value: string): Restaurant[] {
    const filterValue = value.toLowerCase();
    return this.restaurantArray.filter(restaurant => restaurant.displayName.toLowerCase().includes(filterValue));
  }

  getLogoUrl() {
    return `./assets/images/${this.selectedRestaurant.value.restaurantID}/favicon.png`;
  }
  toggleActive(event: any) {
    debugger;
    event.preventDefault();
    if (this.element !== undefined) {
      this.element.style.backgroundColor = "white";
    }
    var target = event.currentTarget;
    target.style.backgroundColor = "#e51282";
    this.element = target;
  }

  isAdmin() {
    if (this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'admin') {
      return true;
    }
    else {
      return false;
    }
  }

  isManager() {
    if (this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'admin' || this.user.restaurants[this.selectedRestaurant.value.restaurantID].role == 'manager') {
      return true;
    }
    else {
      return false;
    }
  }
}
