import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EditTimeDialogComponent } from '../edit-time-dialog/edit-time-dialog.component';

@Component({
  selector: 'app-edit-time-menu',
  templateUrl: './edit-time-menu.component.html',
  styleUrls: ['./edit-time-menu.component.css']
})
export class EditTimeMenuComponent implements OnInit {
  selectedRestaurant: any
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedRestaurant = data.selectedRestaurant
  }

  ngOnInit(): void {
  }

  openEditTimeDialog(type: string) {
    this.dialog.open(EditTimeDialogComponent, {
      width: '100vw',
      height: '100vh',

      data: { type: type, selectedRestaurant: this.selectedRestaurant }
    });
  }
}


