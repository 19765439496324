<div class="col-12">

  
    <form (submit)="handleForm($event)" class="checkout">
  
  
      <div #cardElement>
        <!-- A Stripe Element will be inserted here. -->
      </div>
  
  
      <!-- Used to display Element errors. -->
      <p>{{ cardErrors }}</p>
      <button mat-raised-button class="col-12 m-4" color="primary" > Confirm </button>
      <!-- <button class="btn btn-primary">Order Now</button> -->
    </form>
    <p>{{ cardErrors }}</p>

    <div *ngIf="loading" class="notification is-info">
        <mat-progress-bar mode="indeterminate" color="accent" ></mat-progress-bar>
        <h5>Loading....</h5>
</div>
    <!-- <pre *ngIf="confirmation" style="max-width: 500px;">
    {{ confirmation | json }}
  </pre> -->
    <p *ngIf="confirmation" routerLink="/profile"> Success! </p>
   
    
  </div>