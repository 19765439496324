<a mat-raised-button href="javascript:void()" (click)="toggleTableRows()" color="primary">{{'Toggle
  Rows'|translate}}</a>
<button style='margin-left: 1rem;' type="button" mat-stroked-button (click)="searchSwitch()">
  <mat-icon aria-label="Side nav toggle icon">search</mat-icon>
  <span>{{'Search Orders'|translate}}</span>
</button>
<div *ngIf="searching" [@slideInOut]>
  <div class="text-left m-t-10 ">
    <mat-button-toggle-group [(value)]="searchOption">
      <mat-button-toggle value="Phone Number">{{'Phone Number'|translate}}</mat-button-toggle>
      <mat-button-toggle value="Order Number">{{'Order Number'|translate}}</mat-button-toggle>
      <mat-button-toggle value="First Name">{{'First Name'|translate}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="m-b-10" *ngIf="searchOption=='Phone Number'">
    <mat-form-field class="filter" floatLabel="never">
      <mat-label>{{'Enter phone number here'|translate}}...</mat-label>
      <input matInput (keyup)="applyPhoneFilter()" [formControl]="phoneControl" type="tel" autocomplete="off">
    </mat-form-field>
  </div>
  <div class="m-b-10" *ngIf="searchOption=='Order Number'">
    <mat-form-field class="filter" floatLabel="never">
      <mat-label>{{'Enter order number here'|translate}}...</mat-label>
      <input matInput (keyup)="applyIdFilter()" autocomplete="off" [formControl]="numberControl" type="number">
    </mat-form-field>
  </div>
  <div class="m-b-10" *ngIf="searchOption=='First Name'">
    <mat-form-field class="filter" floatLabel="never">
      <mat-label>{{'Enter first name here'|translate}}...</mat-label>
      <input matInput (keyup)="applyNameFilter()" autocomplete="off" [formControl]="nameControl" type="text">
    </mat-form-field>
  </div>

</div>




<div class="mat-elevation-z8">


  <table mat-table multiTemplateDataRows class="full-width-table" [dataSource]="dataSource" matSort aria-label="Orders">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'Order'|translate}}</th>
      <td mat-cell style="font-weight: bold; color:#e07f11;" *matCellDef="let row"><Span style="color:#e01111;"
          *ngIf="row.canceled">❌</Span>{{row.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="first">
      <th mat-header-cell *matHeaderCellDef>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.first}} {{row.last[0]}}.</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="orderType">
      <th mat-header-cell *matHeaderCellDef>{{'Type'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.orderType == 'Pickup'">
          <mat-icon class="bigIcon">store</mat-icon>
          <p style="width: 100%; text-align: center;">Pickup</p>
        </div>
        <div *ngIf="row.orderType == 'Delivery'">
          <mat-icon class="bigIcon">local_taxi</mat-icon>
          <p style="width: 100%; text-align: center;">Delivery</p>
        </div>
        <div *ngIf="row.orderType == 'Doordash'">
          <mat-icon class="bigIcon">local_shipping</mat-icon>
          <p style="width: 100%; text-align: center; color:#e01111">Doordash</p>
        </div>
        <!-- <div *ngIf="row.orderType == 'Delivery'" class="dis-flex flex-c flex-w" style="max-width: 24px;">
          <mat-icon aria-hidden="false" aria-label="Delivery Icon" matTooltip="Delivery">local_taxi </mat-icon>
          <span class="fs-8">Delivery</span>
        </div>

        <div *ngIf="row.orderType == 'Pickup'" class="dis-flex flex-c flex-w" style="max-width: 24px;">
          <mat-icon aria-hidden="false" aria-label="Delivery Icon" matTooltip="Pickup">
            store</mat-icon>
          <span class="fs-8">Pickup</span>
        </div> -->
      </td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="orderTime">
      <th mat-header-cell *matHeaderCellDef>{{'Schedule Time'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.isFutureOrder == false">ASAP</span>
        <span class="schedule" *ngIf="row.isFutureOrder == true && row.goTime ==true"> {{row.futureOrderTime }}</span>
        <span class="schedule" *ngIf="row.isFutureOrder == true && row.goTime ==false">{{row.futureOrderDate |
          date:"EEEE, MMM dd"}},
          {{row.futureOrderTime }}</span>

      </td>
    </ng-container>

    <!-- Placed At Column -->
    <ng-container matColumnDef="placedAt">
      <th mat-header-cell *matHeaderCellDef>{{'Placed At'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{row.order_iso_time.seconds * 1000 | date:'EEEE, MMM d, h:mm a'}}
      </td>
    </ng-container>


    <!-- Template for details row -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" style="padding: 0 !important;">

        <div class="element-detail"
          [@detailExpand]="orderService.expendedOrders.includes(row.id) ? 'expanded' : 'collapsed'"
          [ngClass]="orderService.expendedOrders.includes(row.id) ? ['element-detail']:['displayNone']">
          <!-- ORDER DETAILS -->
          <order-detail [restaurant]='restaurant' [restaurantDoc]='this.restaurantDoc' [modifiers]='modifiers'
            [order]='row' [inhouseConfig]='inhouseConfig'>
          </order-detail>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="expended(row)"
      [ngClass]="(row.confirmed==false && inhouseConfig.mustConfirm)? ['tableRow','blink-bg']:['tableRow']"
      [class.expanded-row]="row.isExpanded" [class.expandedRow]="row.isExpanded"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="dataSource?.data.length">
  </mat-paginator>
</div>