<button mat-button matSuffix mat-icon-button aria-label="Close dialog" ([mat-dialog-close])="false" (click)="close()"
    style="float: right">
    <mat-icon>close</mat-icon>
</button>
<div>
    <form [formGroup]="form">
        <div class="text-content" formArrayName="notes" *ngFor="let note of notesControls.controls; let i = index">
            <mat-form-field>
                <textarea matInput [formControlName]="i" placeholder="Enter Note"></textarea>
            </mat-form-field>
            <button mat-mini-fab color="accent" (click)="removeItem(i)">
                <mat-icon>delete</mat-icon>
            </button>

        </div>
    </form>
    <button mat-flat-button color="accent" (click)="addItem()">Add Note</button>
    <button mat-flat-button color="primary" (click)="saveItems()">Save Notes</button>
</div>