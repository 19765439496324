<form [formGroup]="holidayForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill">
        <mat-label>Holiday Name</mat-label>
        <mat-select formControlName="name" (selectionChange)="onHolidaySelect($event.value)">
            <mat-option *ngFor="let holiday of usHolidays" [value]="holiday">
                {{ holiday.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Start Time</mat-label>
        <input matInput type="time" formControlName="startTime">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>End Time</mat-label>
        <input matInput type="time" formControlName="endTime">
    </mat-form-field>
    <mat-checkbox formControlName="closed">Closed on this Holiday</mat-checkbox>
    <button mat-raised-button color="primary" type="submit">Submit</button>
</form>