import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
  styleUrls: ['./edit-note-dialog.component.css']
})
export class EditNoteDialogComponent implements OnInit {
  selectedRestaurant: any
  type: string;
  form: FormGroup;
  initialTexts: string[] = ['Hello', 'World', 'Angular', 'Material'];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<EditNoteDialogComponent>, private afs: AngularFirestore, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedRestaurant = data.selectedRestaurant
    this.type = data.type
    this.form = this.fb.group({
      notes: this.fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.type == 'menuNotes') {
      this.selectedRestaurant.value.menuNotes.forEach(text => this.addItem(text));
    } else if (this.type == 'announcement') {
      this.selectedRestaurant.value.announcements.forEach(text => this.addItem(text));
    }
  }

  get notesControls(): FormArray {
    return this.form.get('notes') as FormArray;
  }
  close() {
    this.dialogRef.close()
  }

  addItem(text: string = ''): void {
    this.notesControls.push(this.fb.control(text));
  }

  removeItem(index: number): void {
    this.notesControls.removeAt(index);
  }

  saveItems(): void {
    // This logs the current list of items to the console. Replace with your save logic.
    console.log(this.notesControls.value);
    let temp = { ...this.selectedRestaurant.value }
    if (this.type == 'menuNotes') {
      temp.menuNotes = this.notesControls.value;
    } else if (this.type == 'announcement') {
      temp.announcements = this.notesControls.value;
    }
    this.afs.doc('restaurants/' + this.selectedRestaurant.value.restaurantID).set(temp);
    this.selectedRestaurant.next(temp)
    this.dialogRef.close()
  }
}
