<div>
  <button mat-button matSuffix mat-icon-button aria-label="Close dialog" ([mat-dialog-close])="false" (click)="close()"
    style="float: right">
    <mat-icon>close</mat-icon>
  </button>

  <h3>{{item.name}}</h3>

  <!-- //1.item name
  //2.item price
  //4.item modifier
  //5.item description -->
  <div class="prices">
    <p style="font-size: large;">Prices</p>
    <div  *ngFor="let price of item.basePrices">
      <span class="price">{{price.type}}:</span><span>$</span>
      <mat-form-field class="w-25 my-1" style="display: inline-block; ">
        <input type="number" [(ngModel)]="BasePrices[item.basePrices.indexOf(price)].price" matInput
          [value]="price.price">
      </mat-form-field>
    </div>
  </div>

  <div>
    <h4>Description</h4>
    <mat-form-field class="my-1" style="display: inline-block; ">
      <textarea rows="3" matInput [(ngModel)]="item.description" [value]="item.description">

    </textarea>
      <!-- <input type="text"  matInput > -->
    </mat-form-field>
  </div>


  <div>
    <mat-checkbox [(ngModel)]="item.spicy" (change)="toggled(item, $event)">Spicy</mat-checkbox>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="item.available">In Stock</mat-checkbox>
  </div>




  <button mat-raised-button matStepperNext color="accent" class="button w-100 my-3  block" (click)="confirm()"
    mat-dialog-close>Confirm</button>

</div>