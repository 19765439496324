import { Component, OnInit, Input } from '@angular/core';
import { OrderArrayService } from '../order-array.service';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { RemoveItemComponent } from '../remove-item/remove-item.component';
import { EditOrderDialogComponent } from "../edit-order-dialog/edit-order-dialog.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { orderObject, inhouseConfig } from '../interface';
import * as moment from 'moment-timezone';
import { ChangeTipDialogComponent } from '../change-tip-dialog/change-tip-dialog.component';

@Component({
  selector: 'order-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.css']
})
export class ItemDetailComponent implements OnInit {
  @Input() order: orderObject;
  @Input() inhouseConfig: inhouseConfig;
  @Input() restaurant;
  @Input() restaurantDoc;
  @Input() modifiers;


  constructor(public orderService: OrderArrayService, public dialog: MatDialog, public functions: AngularFireFunctions) {

  }

  public condition;
  confirmation;
  phoneNumber;
  show;
  pickupTime

  ngOnInit() {
    this.phoneNumber = this.formatPhoneNumber(this.order.phoneNum)
    this.pickupTime = moment(this.order.pickupTime).tz('America/Denver').format('YYYY-MM-DD HH:mm:ss')

    let today = moment().tz('America/Denver');


    let orderPlaceDay = moment(this.order.order_iso_time.toMillis()).tz('America/Denver');

    if (this.order.canceled == true) {
      this.show = false;
    }
    else {
      if (this.order.futureOrderDateTime) {
        let futureDay = moment(this.order.futureOrderDateTime.toMillis()).tz('America/Denver');
        if (today.isAfter(futureDay) && !today.isSame(futureDay, 'date')) {
          this.show = false;
        }
        else {
          this.show = true;
        }
      } else {
        if (today.isAfter(orderPlaceDay) && !today.isSame(orderPlaceDay, 'date')) {
          this.show = false;
        }
        else {
          this.show = true;
        }
      }
    }

  }



  async printOrder(order) {
    this.orderService.globalLoading = true;
    const fun = this.functions.httpsCallable('global_printReceipt_v2');
    let print = confirm("Press Ok to Confirm Print Job");
    if (print) {
      try {
        this.confirmation = await fun({ order: order }).toPromise();
        this.orderService.globalLoading = false;
        alert('Print Job Sent!')
      } catch (err) {
        this.orderService.globalLoading = false;
        alert(err);
      }
    } else {
      this.orderService.globalLoading = false;
      alert('Print Job Cancelled')
    }

  }

  async sendReminder(order) {
    this.orderService.globalLoading = true;
    const fun = this.functions.httpsCallable('sendEmailReminder');
    let print = confirm("Press Ok to Send Reminder");
    if (print) {
      try {
        this.confirmation = await fun({ order: order }).toPromise();
        this.orderService.globalLoading = false;
        alert('Reminder Job Sent!')
      } catch (err) {
        this.orderService.globalLoading = false;
        alert(err);
      }
    } else {
      this.orderService.globalLoading = false;
      alert('ReminderCancelled')
    }
  }

  openDialog(order): void {


    //console.log(item);
    let editItem = {
      //mod data
      firstName: order.first,
      lastName: order.last,
      address: order.deliveryAddress,
      order_ID: order.id,
      order_total: order.total,
      order_chargeID: order.charge_ID,
      reason: '',
      amount: ''


      //const data


    }


  }
  openEditDialog(order): void {
    const addDialogRef = this.dialog.open(EditOrderDialogComponent, {
      width: '100vw',
      height: '100vh',
      data: { selectedOrder: order }
    });



  }
  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }
  openAddItemDialog(order): void {
    let dialogRef = this.dialog.open(ConfirmComponent, {
      width: '100%',
      height: '100vh',
      disableClose: true,
      data: { order: order, restaurant: this.restaurantDoc }
    });
  }

  openChangeTipDialog(order): void {
    let dialogRef = this.dialog.open(ChangeTipDialogComponent, {
      width: '100%',
      height: '100vh',
      disableClose: true,
      data: { order: order, restaurant: this.restaurantDoc }
    });
  }


  async GetCustomer() {
    const fun = this.functions.httpsCallable('get_stripe_customer');
    try {
      let result = await fun({
        order: this.order,
      }).toPromise();

      console.log('Order ' + this.order.id, result.invoice_settings.default_payment_method)
      console.log('customer', this.order.user)
    } catch (err) {
      console.error(err);

    }
  }


  openConfirmDialog(order): void {


    //console.log(item);
    let editItem = {
      //mod data
      firstName: order.first,
      lastName: order.last,
      address: order.deliveryAddress,
      order_ID: order.id,
      order_total: order.total,
      order_chargeID: order.charge_ID,
      reason: '',
      amount: ''


      //const data


    }


  }


  isNotComplete(order) {
    // let secs = order.order_iso_time.seconds * 1000;
    // let orderTime = new Date(secs);
    // let today = new Date();
    // today.setHours(0, 0, 0, 0);
    // console.log('today', today);
    // console.log('order', orderTime);
    // if (orderTime > today) {
    //   console.log('true')
    //   return true;
    // } else {
    //   console.log('false')
    //   return false;
    // }
    return true
  }




  openRemoveDialog(order): void {

    console.log("this.restaurant: ", this.restaurant)
    let dialogRef = this.dialog.open(RemoveItemComponent, {
      height: '100%',
      width: '100%',
      data: { order: order, restaurant: this.restaurantDoc }
    });

  }



  async refund(selectedOrder) {
    this.orderService.globalLoading = true;
    // console.log(selectedOrder);
    const callFoo = this.functions.httpsCallable('global_stripeMakeRefund_v2');



    let doRefund = confirm(`Refund/Cancel Order # ${selectedOrder.id}?`);
    if (doRefund) {
      try {
        let response = await callFoo({ order: selectedOrder }).toPromise();
        // alert(response.Transaction.TransactionResult);
        this.orderService.globalLoading = false;
      } catch (err) {
        this.orderService.globalLoading = false;
        alert(err);
      }
    } else {
      this.orderService.globalLoading = false;
    }
    // console.log(response);
    this.orderService.globalLoading = false;
  }

  updateOrderState(object, id, event) {
    object.state = event.value;
    // console.log(event.value)
    return this.orderService.updateOrderStatus(id, event.value);
  }





}
