import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrderArrayService } from '../order-array.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';

@Component({
  selector: 'app-holiday-form',
  templateUrl: './holiday-form.component.html',
  styleUrls: ['./holiday-form.component.css']
})
export class HolidayFormComponent implements OnInit {
  constructor(private orderService: OrderArrayService, private dialogRef: MatDialogRef<HolidayFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private afs: AngularFirestore,) {
    this.selectedRestaurant = data.selectedRestaurant
  }
  holidayForm!: FormGroup;
  selectedRestaurant: any
  // Updated list of US holidays with 2025 dates
  usHolidays = this.orderService.holidays

  ngOnInit(): void {
    this.holidayForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      startTime: new FormControl('', [Validators.required]),
      endTime: new FormControl('', [Validators.required]),
      closed: new FormControl(false)
    });
    this.holidayForm.get('closed').valueChanges.subscribe((isClosed: boolean) => {
      const startTimeControl = this.holidayForm.get('startTime');
      const endTimeControl = this.holidayForm.get('endTime');

      if (isClosed) {
        // Clear the values and remove validators
        startTimeControl.setValue('');
        startTimeControl.clearValidators();
        endTimeControl.setValue('');
        endTimeControl.clearValidators();
      } else {
        // Re-apply validators and keep them required
        startTimeControl.setValidators([Validators.required]);
        endTimeControl.setValidators([Validators.required]);
      }

      // Update validity of the controls
      startTimeControl.updateValueAndValidity();
      endTimeControl.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.holidayForm.valid) {
      console.log('Form Data:', this.holidayForm.value);
      // Handle form submission, e.g., save to a database
      const closed = this.holidayForm.value.closed
      const formData = {
        ...this.holidayForm.value,
        startTime: closed ? this.holidayForm.value.startTime : this.convertTime12hr(this.holidayForm.value.startTime),
        endTime: closed ? this.holidayForm.value.endTime : this.convertTime12hr(this.holidayForm.value.endTime)
      };
      console.log(this.holidayForm.value);
      let temp = { ...this.selectedRestaurant.value }
      temp.holidayHours.push(formData);
      console.log("formData:", formData)
      this.afs.doc('restaurants/' + this.selectedRestaurant.value.restaurantID).set(temp);
      this.selectedRestaurant.next(temp)
      this.dialogRef.close()
    }
  }
  convertTime12hr(time24: string): string {
    // Ensure that the input is a string
    if (typeof time24 !== 'string') return '';

    // Split the time into hours and minutes
    const [sHours, minutes] = time24.split(':');

    // Parse hours as integer
    const hours = parseInt(sHours);

    // Determine the period and adjust hours for AM/PM
    const period = hours >= 12 ? 'pm' : 'am';
    const adjustedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Return formatted time string
    return `${adjustedHours}:${minutes} ${period}`;
  }
  onDateChange(event) {
    // Convert Date to moment instance and format it
    const formattedDate = moment(event.value).format('YYYY-MM-DD');
    this.holidayForm.get('date').setValue(formattedDate, { emitEvent: true });
  }

  onHolidaySelect(holiday: { name: string, date: string }) {
    if (holiday) {
      this.holidayForm.controls['date'].setValue(holiday.date);
    }
  }
}