import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EditNoteDialogComponent } from '../edit-note-dialog/edit-note-dialog.component';

@Component({
  selector: 'app-edit-notes-menu',
  templateUrl: './edit-notes-menu.component.html',
  styleUrls: ['./edit-notes-menu.component.css']
})
export class EditNotesMenuComponent implements OnInit {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedRestaurant = data.selectedRestaurant
  }
  selectedRestaurant: any
  ngOnInit(): void {
  }
  openEditNoteDialog(type: string) {
    this.dialog.open(EditNoteDialogComponent, {
      width: '100vw',
      height: '100vh',
      data: { type: type, selectedRestaurant: this.selectedRestaurant }
    });
  }
}
