<button mat-button matSuffix mat-icon-button aria-label="Close dialog" ([mat-dialog-close])="false" (click)="close()"
    style="float: right">
    <mat-icon>close</mat-icon>
</button>
<h3>{{type}} Time Edit</h3>
<form [formGroup]="weekForm" (ngSubmit)="onSubmit()">
    <div
        *ngFor="let day of ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];let dayIndex = index">
        <h3> {{ day | titlecase }}</h3>

        <div class="time-section" [formGroupName]="day">
            <mat-checkbox formControlName="closed" (change)="onDayClosedChange(day, $event.checked)">
                Closed
            </mat-checkbox>
            <mat-form-field appearance="fill" *ngIf="!weekForm.get(day + '.closed').value">
                <mat-label> Open Time</mat-label>
                <input type="text" matInput formControlName="open" [matAutocomplete]="auto"
                    (input)="filterTimes($event, dayIndex, 'open')">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let time of filteredTimeOptions[dayIndex]" [value]="time">{{ time
                        }}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="weekForm.get(day + '.open').hasError('invalidTimeFormat')">
                    Invalid time format, e.g., "11:00 am"
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="!weekForm.get(day + '.closed').value">
                <mat-label> Close Time</mat-label>
                <input type="text" matInput formControlName="close" [matAutocomplete]="autoClose"
                    (input)="filterTimes($event, dayIndex, 'close')">
                <mat-autocomplete #autoClose="matAutocomplete">
                    <mat-option *ngFor="let time of filteredTimeOptions[dayIndex]" [value]="time">{{ time
                        }}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="weekForm.get(day +'.closed').hasError('invalidTimeFormat')">
                    Invalid time format, e.g., "11:00 am"
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <button mat-raised-button color="primary" type="submit">Save Times</button>
</form>