<mat-card class="m-l-20 m-r-20 m-b-20">
    <h3 class="text-center">{{'menu.Menu_Items'|translate}}</h3>

    <mat-form-field [style.fontSize.px]="16" class="w-100">
        <input class="form-control" (focus)="loadSearch()" type="search" placeholder="Search for Item..." aria-label="Number" matInput
          [formControl]="itemControl" matInput [matAutocomplete]="auto">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete (optionSelected)='openDialog(getItem($event.option.value))' #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    <!-- <div class="flex-wrap justify-start m-1 w-100">
        <button mat-raised-button color="accent" (click)="toggleEditingMode()" class="m-1"><span
                *ngIf="!editingMode">{{'menu.Start_Editing'|translate}}</span><span
                *ngIf="editingMode">{{'menu.Update'|translate}}!</span></button>

        <button class="m-1" *ngIf="editingMode" mat-raised-button color="primary"
            (click)="cancelEditing()">{{'menu.Cancel'|translate}}</button>
    </div> -->

    <div *ngFor="let group of groups">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{group}}
                </mat-panel-title>

            </mat-expansion-panel-header>
            <div *ngFor="let item of menuItems">
                <div *ngIf="item.group == group" class="menuRow">
                    <span class="itemName"> {{item.name}} </span>
                    <!-- <mat-checkbox [disabled]="!editingMode" [(ngModel)]="item.available"
                            (change)="toggled(item, $event)">In
                            Stock</mat-checkbox> -->
                    <div class="w-100" *ngFor="let bp of item.basePrices">
                        <div>{{bp.type}}: {{bp.price | currency}}</div>
                    </div>
                    <button  mat-raised-button (click)="openEditDialog(item)" color="accent"
                        class="m-1 right">Edit</button>
                </div>

            </div>


        </mat-expansion-panel>

        <!-- <h2 class="px-1 text-bold m-t-16 m-b-4">{{group}}</h2>
        <div *ngFor="let item of menuItems">
            <div *ngIf="item.group == group" class="menuRow">
                <span class="itemName"> {{item.name}} </span>
                <mat-checkbox [disabled]="!editingMode" [(ngModel)]="item.available" (change)="toggled(item, $event)">In
                    Stock</mat-checkbox>
                <div class="w-100" *ngFor="let bp of item.basePrices">
                    <div>{{bp.type}}: {{bp.price | currency}}</div>

                </div>
            </div>
        </div> -->
    </div>
</mat-card>

<!-- <mat-expansion-panel [expanded]="true">
    <mat-panel-title>
        Group
    </mat-panel-title>
    <mat-panel-description>
        one Group!
    </mat-panel-description>

</mat-expansion-panel> -->