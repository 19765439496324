<div *ngIf="!(afAuth.authState | async); else loggedIn">
  <app-login></app-login>
</div>
<ng-template #loggedIn>
  <mat-sidenav-container class="sidenav-container" *ngIf="viewReady; else loading" autosize>
    <mat-sidenav #drawer mode="side" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" class="sidenav"
      [mode]="(isMedium$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
      <div style="border-bottom: 0.8px solid #e0e0e041">
        <img class="menuLogo" *ngIf="isExpanded" src="../../assets/logo.png" alt="" style="width: 175px">
        <img *ngIf="!isExpanded" src="../../assets/favicon.png" alt="" style="width: 50px; margin: 10px">

      </div>
      <mat-toolbar color="primary" class="nav-bar mat-elevation-z2">

        <span class="spacer"></span>
        <ng-container>
          <mat-icon>language</mat-icon>
          <button mat-button [matMenuTriggerFor]="menu">
            <span *ngIf="isExpanded"> {{siteLanguage}}</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="switchLanguage('en')">English</a>
            <a mat-menu-item (click)="switchLanguage('cn')">中文</a>
          </mat-menu>
        </ng-container>
      </mat-toolbar>


      <mat-nav-list class="m-b-16">

        <mat-list-item [routerLink]="['',selectedRestaurant.value.restaurantID]" routerLinkActive="activeRoute"
          class="sideNavText" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Orders">text_snippet
          </mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'orders'|translate}}</span>
        </mat-list-item>


        <mat-list-item mat-list-item [routerLink]="['/stats',selectedRestaurant.value.restaurantID]"
          [state]='{restaurantPricing:restaurantPricing, selectedRestaurant:selectedRestaurant}' class="sideNavText"
          routerLinkActive="activeRoute">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Report">assessment</mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'Report'|translate}}
          </span>


        </mat-list-item>

        <mat-list-item mat-list-item [routerLink]="['/menu',selectedRestaurant.value.restaurantID]" class="sideNavText"
          routerLinkActive="activeRoute">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Menu">restaurant_menu</mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'Menu'|translate}}
          </span>
        </mat-list-item>


        <mat-list-item mat-list-item [routerLink]="['/admin',selectedRestaurant.value.restaurantID]"
          [state]='{selectedRestaurant:selectedRestaurant}' class="sideNavText" routerLinkActive="activeRoute">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Order Time">more_time</mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'Settings'|translate}}
          </span>
        </mat-list-item>

        <mat-list-item [routerLink]="['/checkins',selectedRestaurant.value.restaurantID]"
          [state]='{selectedRestaurant:selectedRestaurant}' routerLinkActive="activeRoute" class="sideNavText"
          *ngIf="selectedRestaurant.value.acceptsCheckins == true">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Check Ins">fact_check</mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'Check Ins'|translate}}
          </span>
        </mat-list-item>

        <mat-list-item [routerLink]="['/reservations',selectedRestaurant.value.restaurantID]"
          [state]='{selectedRestaurant:selectedRestaurant, reservations:reservations,todaysReservations:todaysReservations}'
          routerLinkActive="activeRoute" class="sideNavText">
          <mat-icon aria-hidden="false" class="menuIcon" matTooltip="Reservations">calendar_today</mat-icon>
          <span *ngIf="isExpanded" class="m-l-8">{{'Reservations'|translate}}
          </span>
        </mat-list-item>


      </mat-nav-list>


      <div class="clapse">
        <div *ngIf="isExpanded">
          <div class="signOut">
            <p>{{user.email}}</p>
            <button mat-icon-button (click)="signOut()">
              <mat-icon class="m-r-10" matTooltip="Sign Out">logout</mat-icon>
              <span *ngIf="isExpanded">
                {{'Sign Out'|translate}}
              </span>
            </button>
          </div>
        </div>

        <div style="border-top: 0.8px solid #e0e0e041; width: 100%;">
          <mat-icon class="clapseIcon" *ngIf="isExpanded" aria-hidden="false" (click)="isExpanded = !isExpanded">
            chevron_left</mat-icon>
          <mat-icon class="clapseIcon" *ngIf="!isExpanded" aria-hidden="false" (click)="isExpanded = !isExpanded">
            chevron_right</mat-icon>
        </div>

      </div>

    </mat-sidenav>


    <mat-sidenav-content *ngIf="viewReady; else loading"
      [ngClass]="{'inactive':(selectedRestaurant.value.acceptingOrders==false), 'active':(selectedRestaurant.value.acceptingOrders==true) }">
      <mat-toolbar style="z-index: 10;">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <button mat-button [matMenuTriggerFor]="menu">
          <img *ngIf="selectedRestaurant.value.imageLinks" class="avatar"
            [src]="selectedRestaurant.value.imageLinks.icon" alt="Avatar" onerror="this.style.display = 'none'"
            width="30" height="30">
          {{selectedRestaurant.value.displayName}}
        </button>
        <mat-menu #menu="matMenu" xPosition='before'>
          <ng-template matMenuContent let-items='menuItems'>

            <button *ngFor="let r of restaurantArray" mat-menu-item (click)="switchRestaurant(r.restaurantID)">
              {{r.displayName}}
            </button>
          </ng-template>
        </mat-menu>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="searchSwitch()">
          <mat-icon aria-label="Side nav toggle icon">search</mat-icon>
        </button>
        <div *ngIf='showSearch'>

          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Restaurant</mat-label>
            <input type="text" aria-label="Restaurant" matInput [formControl]="RestaurantSwithControl"
              [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredRestaurant | async" [value]="option.displayName"
                (click)="switchRestaurant(option.restaurantID)">
                {{option.displayName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>

        <div>
          <button *ngIf="newResCount > 0" mat-flat-button color="accent" style="margin-right: .5rem;"
            [state]='{selectedRestaurant:selectedRestaurant}'
            [routerLink]="['/reservations',selectedRestaurant.value.restaurantID]">{{newResCount}} New Reservation<span
              *ngIf="newResCount > 0">s</span>
          </button>

        </div>

        <div *ngIf="checkInCount > 0">
          <button *ngIf="checkInCount > 0" mat-flat-button color="accent"
            [routerLink]="['/checkins',selectedRestaurant.value.restaurantID] "
            [state]='{selectedRestaurant:selectedRestaurant}'> {{checkInCount}} Guests Waiting
          </button>
        </div>


        <button color="primary" *ngIf="selectedRestaurant.value.acceptingOrders" mat-stroked-button class="activeButton"
          [routerLink]="['/admin',selectedRestaurant.value.restaurantID]"
          [state]='{selectedRestaurant:selectedRestaurant}'>{{'Accepting Orders'|translate}}
        </button>
        <button color="primary" *ngIf="!selectedRestaurant.value.acceptingOrders" mat-stroked-button
          class="notActiveButton" [routerLink]="['/admin',selectedRestaurant.value.restaurantID]"
          [state]='{selectedRestaurant:selectedRestaurant}'>{{'Not Accepting Orders'|translate}}
        </button>
      </mat-toolbar>

      <app-global-loading></app-global-loading>

      <ng-content></ng-content>



    </mat-sidenav-content>

  </mat-sidenav-container>
  <ng-template #loading>
    <div style="margin: 1rem; display: flex; flex-wrap: wrap; justify-content: center;">
      <mat-spinner></mat-spinner>
    </div>
    <p style="display: flex; flex-wrap: wrap; justify-content: center;">{{'Loading'|translate}}... </p>
  </ng-template>

</ng-template>