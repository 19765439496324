<mat-card *ngIf="orderService.isManager()">
    <h3 class="mb-0">{{ orderService.selectedRestaurant.value.displayName }} Authorized Users</h3>
    <p *ngFor="let user of users | async">
      {{ user.email }} <a href="" *ngIf="!user.email.includes('@developdenver.tech')"
        (click)="removeUser(user.email)">Remove</a>
    </p>
    <div class="left">
      <mat-form-field appearance="outline">
        <input matInput type="email" placeholder="Type email here..." [(ngModel)]="authorizeEmail">
        <button mat-button *ngIf="authorizeEmail" matSuffix mat-icon-button aria-label="Clear"
          (click)="authorizeEmail=''">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint>Add new User</mat-hint>
      </mat-form-field>
    </div>
    <div class="right">
      <button mat-raised-button color="accent" (click)="authorizeUser()">Add New User</button>
    </div>
  </mat-card>